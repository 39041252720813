
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { getUserProfile, removeSweetAlert, updateProfilePicture, updateUserProfile } from "../../actions/profileActions";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  FormGroup,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import { connect } from 'react-redux';
import { updateAdminProfile } from '../../actions/profileActions';

const EditProfile = ({ updateAdminProfile, updateProfilePicture, user, userData, isLoading, successMsg, sweetAlert, removeSweetAlert, getUserProfile, updateUserProfile }) => {
  const history = useHistory();
  const [singleFileName, setSingleFileName] = React.useState("");

  const [singleFile, setSingleFile] = React.useState(null);

  const singleFileRef = React.useRef();

  const handleSingleFileInput = (e) => {
    singleFileRef.current.click(e);
  };

  // useEffect(() => {
  //   getUserProfile()
  // }, [])

  const addSingleFile = (e, type) => {
    let fileNames = "";
    let files = e.target.files;
    for (let i = 0; i < e.target.files.length; i++) {
      fileNames = fileNames + e.target.files[i].name;
    }
    setSingleFile(files);
    setSingleFileName(fileNames);

  };

  // Loading Spinner
  const [loadingSpinner, setLoadingSpinner] = useState(false)

  useEffect(() => {

    setLoadingSpinner(isLoading)

  }, [isLoading])

  // Sweet Alert

  const [alert, setAlert] = React.useState(null);
  React.useEffect(() => {
    getUserProfile()
    return function cleanup() {
      var id = window.setTimeout(null, 0);
      while (id--) {
        window.clearTimeout(id);
      }
    };
  }, []);

  useEffect(() => {
    if (sweetAlert) {
      successAlert()


    } else {
      hideAlert()
    }
  }, [sweetAlert])

  const successAlert = () => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Success!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
      >
        {successMsg}
      </SweetAlert>
    );
  }
  const hideAlert = () => {
    setAlert(null);
    removeSweetAlert()
  };

  // Form Submission


  const [formdata, setFormdata] = useState({
    fname: "",
    lname: '',
    address: '',
    post_code: '',
    post_code: '',
    mobile_no: '',
    email: '',
    pic: '',
    town: '',
    notes: '',
    status: '1',
    phone_no: '',
    password: '',
    c_password: ''
  })

  useEffect(() => {
    setFormdata({
      fname: userData?.fname || "",
      lname: userData?.lname || "",
      address: userData?.address || "",
      post_code: userData?.post_code || "",
      // post_code: userData?.fname || "",
      mobile_no: userData?.mobile_no || "",
      email: userData?.email || "",
      pic: userData?.pic || "",
      town: userData?.town || "",
      notes: userData?.notes || "",
      status: '1',
      phone_no: userData?.phone_no || "",
      password: userData?.password || "",
      c_password: userData?.password || ""
    })
  }, [userData])

  var { fname, lname, address, post_code, mobile_no, phone_no, email, pic, town, notes, status, password, c_password } = formdata;
  const onChange = e => setFormdata({ ...formdata, [e.target.name]: e.target.value })
  const onChange1 = e => setFormdata({ ...formdata, [e.target.name]: e.target.files[0] })

  const handleImageUpdate = (e) => {
    var formdata = new FormData();
    formdata.append("pic", e.target.files[0]);

    if (e.target.files[0])
    updateProfilePicture(formdata, history)
  }

  const onSubmit = e => {
    e.preventDefault();

    if (password === c_password) {

      if (fname === '') {
        fname = user.fname
      }
      if (lname === '') {
        lname = user.lname
      }
      if (address === '') {
        address = user.address
      }
      if (post_code === '') {
        post_code = user.post_code
      }
      if (mobile_no === '') {
        mobile_no = user.mobile_no
      }
      if (phone_no === '') {
        phone_no = user.phone_no
      }
      if (email === '') {
        email = user.email
      }
      if (town === '') {
        town = user.town
      }
      if (notes === '') {
        notes = user.notes
      }

      if (status === '') {
        status = user.status
      }
      if (pic === '') {
        pic = user.pic
      }




      var formdata = new FormData();
      formdata.append("fname", fname);
      formdata.append("lname", lname);
      formdata.append("address", address);
      formdata.append("post_code", post_code);
      formdata.append("mobile_no", mobile_no);
      formdata.append("phone_no", mobile_no);
      formdata.append("email", email);
      formdata.append("pic", pic);
      formdata.append("town", town);
      formdata.append("notes", notes);
      formdata.append("status", status);
      formdata.append("password", password);

      updateAdminProfile(formdata, history)

      setFormdata({
        fname: '',
        lname: '',
        address: '',
        post_code: '',
        post_code: '',
        mobile_no: '',
        email: '',
        pic: '',
        town: '',
        notes: '',
        status: '1',
        phone_no: '',
        password: '',
        c_password: ''
      })

    } else {
      alert('Passwords don\'t match')
    }



  }

  return (
    <>
      <PanelHeader size="sm" />
      <div className="content">
        {alert}
        <Form onSubmit={e => onSubmit(e)} encType="multipart/form-data">

          <Row>
            <Col md="8" className="">
              <Card>
                <CardHeader>
                  <h5 className="title">Edit Profile</h5>
                </CardHeader>
                <CardBody>
                  <Row>

                    <Col md="6">
                      <FormGroup>
                        <label>First Name</label>
                        <Input

                          type="text"
                          placeholder={user?.fname}
                          name="fname"
                          value={fname}
                          onChange={e => onChange(e)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label>Last Name</label>
                        <Input

                          type="text"
                          placeholder={user?.lname}
                          name="lname"
                          value={lname}
                          onChange={e => onChange(e)}
                        />
                      </FormGroup>
                    </Col>
                    {/* <Col md="6">
                      <FormGroup>
                        <label>Contact Name</label>
                        <Input

                          placeholder=""
                          type="text"
                        />
                      </FormGroup>
                    </Col> */}

                  </Row>

                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>Address</label>
                        <Input

                          type="text"
                          placeholder={user?.address}
                          name="address"
                          value={address}
                          onChange={e => onChange(e)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="" md="4">
                      <FormGroup>
                        <label>City</label>
                        <Input
                          type="text"
                          placeholder={user?.town}
                          name="town"
                          value={town}
                          onChange={e => onChange(e)}
                        />
                      </FormGroup>
                    </Col>
                    {/* <Col className="px-1" md="4">
                      <FormGroup>
                        <label>Country</label>
                        <Input

                          placeholder=""
                          type="text"
                        />
                      </FormGroup>
                    </Col> */}
                    <Col className="" md="4">
                      <FormGroup>
                        <label>Postal Code</label>
                        <Input

                          type="number"
                          placeholder={user?.post_code}
                          name="post_code"
                          value={post_code}
                          onChange={e => onChange(e)}

                        />
                      </FormGroup>
                    </Col>
                    <Col md="4" className="">
                      <FormGroup>
                        <label>Contact #</label>
                        <Input
                          type="number"
                          placeholder={user?.mobile_no}
                          name="mobile_no"
                          value={mobile_no}
                          onChange={e => onChange(e)} />
                      </FormGroup>
                    </Col>

                  </Row>
                  <Row>

                    {/* <Col md="4">
                      <FormGroup>
                        <label>Contact Name</label>
                        <Input type="text" />
                      </FormGroup>
                    </Col> */}
                    <Col md="4" className="">
                      <FormGroup>
                        <label>Email</label>
                        <Input
                          type="email"
                          placeholder={user?.email}
                          name="email"
                          value={email}
                          onChange={e => onChange(e)}

                        />
                      </FormGroup>
                    </Col>
                    <Col md="4" className="">
                      <FormGroup>
                        <label>Password</label>
                        <Input type="password" name="password"
                          value={password}
                          onChange={e => onChange(e)} />
                      </FormGroup>
                    </Col>
                    {/* <Col md="4" className="pl-1">
                      <FormGroup>
                        <label>Confirm Password</label>
                        <Input type="password" name="c_password"
                          value={c_password}
                          onChange={e => onChange(e)} />
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup>
                        <label>Notes Box</label>
                        <Input
                          cols="80"
                          className="border"
                          rows="4"
                          type="textarea"
                          placeholder={user?.notes}

                          name="notes"
                          value={notes}
                          onChange={e => onChange(e)}

                        />
                      </FormGroup>
                    </Col> */}
                  </Row>
                  {/* <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>About Me</label>
                        <Input
                          cols="80"
                          defaultValue="Lamborghini Mercy, Your chick she so thirsty, I'm in
                            that two seat Lambo."
                          placeholder="Here can be your description"
                          rows="4"
                          type="textarea"
                        />
                      </FormGroup>
                    </Col>
                  </Row> */}
                  <div className="text-right">
                    {
                      loadingSpinner ? (
                        <div class="spinner-border text-info" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>

                      ) : (
                        <Button color="info" size="sm" type="submit" >
                          Update
                        </Button>
                      )
                    }
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card className="card-user">
                <div className="image" style={{ height: "180px" }}>
                  <img alt="..." src={require("assets/img/bg5.jpg").default} />
                </div>
                <CardBody>
                  <div className="author">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="avatar border-gray"
                        src={`https://api.eagleiq.online/${user?.pic}`}
                      />
                      <h5 className="title">{user?.fname + ' ' + user?.lname}</h5>
                    </a>
                    <p className="description">{user?.email}</p>
                    {/* <CardTitle tag="h4">Single File</CardTitle> */}

                    <FormGroup className="form-file-upload form-file-simple">
                      <Input
                        type="text"
                        className="inputFileVisible btn btn-info bg-info white-input-field text-white rounded"
                        placeholder="Update Profile Picture"
                        onClick={(e) => handleSingleFileInput(e)}
                        defaultValue={pic?.name}
                      />
                      <input
                        type="file"
                        className="inputFileHidden"
                        style={{ zIndex: -1 }}
                        ref={singleFileRef}
                        // onChange={(e) => addSingleFile(e)}
                        name="pic"
                        onChange={e => handleImageUpdate(e)}

                      />
                    </FormGroup>



                  </div>
                  {/* <p className="description text-center">
                  {'"'}Lamborghini Mercy <br />
                  Your chick she so thirsty <br />
                  I'm in that two seat Lambo{'"'}
                </p> */}
                </CardBody>
                {/* <hr />
              <div className="button-container">
                <Button
                  className="btn-icon btn-round"
                  color="neutral"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  size="lg"
                >
                  <i className="fab fa-facebook-square" />
                </Button>
                <Button
                  className="btn-icon btn-round"
                  color="neutral"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  size="lg"
                >
                  <i className="fab fa-twitter" />
                </Button>
                <Button
                  className="btn-icon btn-round"
                  color="neutral"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  size="lg"
                >
                  <i className="fab fa-google-plus-square" />
                </Button>
              </div> */}
              </Card>
            </Col>
          </Row>
        </Form>

      </div>
    </>
  );
}

const mapStateToProps = state => ({

  user: state.authReducer.user,
  isLoading: state.profileReducer.isLoading,
  sweetAlert: state.profileReducer.sweetAlert,
  userData: state.profileReducer.userProfile,

  successMsg: state.profileReducer.updatedAdminProfile.message,




})

export default connect(mapStateToProps, { updateAdminProfile, updateProfilePicture, removeSweetAlert, getUserProfile, updateUserProfile })(EditProfile); 
