import { combineReducers } from 'redux';
import alertReducer from './alertReducer';
import authReducer from './authReducer';
import profileReducer from './profileReducer';
import sharedReducer from './sharedReducer';
import notesReducer from './notesReducer';



export default combineReducers({
    alertReducer,
    authReducer,
    profileReducer,
    shared: sharedReducer,
    notesReducer
})