// ADdding a global toke


const setAuthToken = token => {
    //Take token from local storage
    var globalToken;
    if (token) {
        globalToken = token

    }
    else {
        globalToken = ''
    }


}

export default setAuthToken;