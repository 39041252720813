import {
    CHANGE_GLOBAL_LOADER,
} from "../actions/types";

const initialState = {
    globalLoader: false,
}

export default function (state = initialState, { type, payload }) {
    console.log('TYPE::')
    console.log(type)
    console.log('PAYLOAD::')
    console.log(payload)
    switch (type) {
        case CHANGE_GLOBAL_LOADER:
            return {
                ...state,
                globalLoader: payload
            }
        default:
            return state;
    }
}