
import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { setAlert } from "actions/alertActions";
import PropTypes from 'prop-types'
import Alert from "views/Tables/Alert";
import {
  login,
  ChangeIsAuthenticated,
} from "actions/authActions";
import { Redirect } from "react-router-dom";


// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Form,
  Container,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
} from "reactstrap";
import { Link } from 'react-router-dom';

// core components
import nowLogo from "assets/img/logo1.png";

import bgImage from "assets/img/bg14.jpg";

const LoginPage = (props) => {
  const {
    setAlert,
    login,
    isAuthenticated,
    loading,
    token,
    ChangeIsAuthenticated,
  } = props
  const [firstnameFocus, setfirstnameFocus] = React.useState(false);
  const [lastnameFocus, setlastnameFocus] = React.useState(false);


  // ---------- Login FormData ----------------------

  const [formdata, setFormdata] = useState({
    email: '',
    password: '',
  })

  const { email, password } = formdata;
  const onChange = e => setFormdata({ ...formdata, [e.target.name]: e.target.value })
  const onSubmit = e => {
    e.preventDefault();
    props.login({ email, password })

  }

  console.log("ISAUTHENTICATED")
  console.log(isAuthenticated)
  console.log("TOKEN")
  console.log(token)

  useEffect(() => {
    if (localStorage.getItem('token') == null) {
      ChangeIsAuthenticated(false)
    }
    document.body.classList.add("login-page");
    return function cleanup() {
      document.body.classList.remove("login-page");
    };
  }, []);

  if (isAuthenticated == null) {
    return null
  }

  if (isAuthenticated == true) {
    return <Redirect to="/admin/dashboard" />
  }

  return (
    <>
      <>
        <div className="content">
          <div className="login-page">
            <Container>
              <Alert />
              <Col xs={12} md={8} lg={4} className="ml-auto mr-auto">
                <Form onSubmit={e => onSubmit(e)}>
                  <Card className="card-login card-plain">
                    <CardHeader>
                      <div className="logo-container w-100">
                        {/* <img src={nowLogo} width="120" alt="now-logo" /> */}
                        <h4 className="text-center text-white">Login</h4>
                      </div>
                    </CardHeader>
                    <CardBody className="">
                      <InputGroup
                        className={
                          "no-border form-control-lg " +
                          (firstnameFocus ? "input-group-focus" : "")
                        }
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons users_circle-08" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="email"
                          name="email"
                          required
                          placeholder="admin@admin.com"
                          value={email}
                          onFocus={(e) => setfirstnameFocus(true)}
                          onBlur={(e) => setfirstnameFocus(false)}
                          onChange={e => onChange(e)}
                        />
                      </InputGroup>
                      <InputGroup
                        className={
                          "no-border form-control-lg " +
                          (lastnameFocus ? "input-group-focus" : "")
                        }
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-key" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="password"
                          name="password"
                          required
                          placeholder="12345678Za"
                          value={password}
                          onFocus={(e) => setlastnameFocus(true)}
                          onBlur={(e) => setlastnameFocus(false)}
                          onChange={e => onChange(e)}
                        />
                      </InputGroup>
                    </CardBody>
                    <CardFooter>
                      {
                        loading ? (
                          <div className="text-center">
                            <div class="spinner-border text-info" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>

                          </div>
                        ) : (
                          <Button
                            block
                            color="info"
                            size="lg"
                            type="submit"
                            className="mb-3 btn-round"
                          >
                            Get Started
                          </Button>

                        )
                      }

                      {/* <div className="pull-left">
                      <h6>
                        <Link to="/auth/register-page" className="link footer-link">
                          Create Account
                        </Link>
                      </h6>
                    </div>
                    <div className="pull-right">
                      <h6>
                        <a href="javascript:void(0)" className="link footer-link">
                          Need Help?
                        </a>
                      </h6>
                    </div> */}
                    </CardFooter>
                  </Card>
                </Form>
              </Col>
            </Container>
          </div>
        </div>
        <div
          className="full-page-background"
          style={{ backgroundImage: "url(" + bgImage + ")" }}
        />
      </>
    </>
  );
}

LoginPage.propTypes = {
  setAlert: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool
}
const mapStateToProps = state => ({

  isAuthenticated: state.authReducer.isAuthenticated,
  token: state.authReducer.token,
  loading: state.authReducer.loading


})
export default connect(mapStateToProps, {
  setAlert,
  login,
  ChangeIsAuthenticated,
})(LoginPage);
