import {
    GET_ALL_MANAGERS,
    ADD_NEW_MANAGER,
    GET_BASE_URL,
    GET_ALL_STAFF,
    GET_ALL_CLIENTS,
    GET_ALL_SITES,
    GET_ALL_STOCKS,
    GET_ALL_VIDEOS,
    UPDATE_ADMIN_PROFILE,
    UPDATE_MANAGER,
    DELETE_MANAGER,
    ADD_NEW_STAFF,
    UPDATE_STAFF,
    DELETE_STAFF,
    ADD_NEW_CLIENT,
    UPDATE_CLIENT,
    DELETE_CLIENT,
    UPDATE_SITE,
    ADD_NEW_SITE,
    DELETE_SITE,
    GET_USER_PROFILE,
    UPDATE_STOCK,
    ADD_NEW_STOCK,
    DELETE_STOCK,
    GET_ALL_STOCK_CATEGORIES,
    ADD_NEW_VIDEO,
    GET_VIDEO_CATEGORIES,
    UPDATE_VIDEO,
    DELETE_VIDEO,
    ADD_STOCK_CATEGORY,
    ADD_VIDEO_CATEGORY,
    SET_IS_LOADING,
    SET_SWEET_ALERT,
    FETCH_ERROR,
    REMOVE_SWEET_ALERT,
    GET_ALL_JOBS,
    ADD_NEW_JOB,
    UPLOAD_FILE,
    DELETE_JOB,
    GET_ALL_LEAVE_REQUESTS,
    GET_ALL_USERS,
    GET_ALL_TIMESHEETS,
    CHANGE_LEAVE_STATUS,
    IS_CREATING_JOB,
    GET_SITE_PROFILE,
    GET_STOCK_REQUESTS,
    CHANGE_STOCK_STATUS,
    CHANGE_GLOBAL_LOADER,
    GET_LATEST_JOB,
    GET_DASHBOARD_DATA,
    UPDATE_USER_PROFILE
} from "./types";
import { setAlert } from './alertActions';

export const getBaseURl = () => dispatch => {
    var baseUrl = 'https://api.eagleiq.online/';
    dispatch({
        type: GET_BASE_URL,
        payload: baseUrl
    })
}
// All Users
export const getAllUsers = () => dispatch => {



    var myHeaders = new Headers();
    myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6Imh4bG1BdzZhc2NrZ1Z3NlhCNEtGSFE9PSIsInZhbHVlIjoicHdhNmxscUdJM1phUzVRTktqNU4wRnJGT0dISUMwOWJuYnhjUVI5ejZlQXozWUxCY050eXRyMEJDZk51WkszUklOcmplRGhGNkZqd0UrRzlBZkJzK2FBcDdERGtxV2pUKzJnallyR1AvL21IQ0h4Y014RzZpUmNSbEVjT25iZjMiLCJtYWMiOiI3MWM2NjcyZTA1NTIyMjllOTdmOWIwZGE3YjM5YTY2NDBhYWU1MTQ5N2M4YjQ2MTJjZjMxNzYxNDNkZDdlZTZiIn0%3D; laravel_session=eyJpdiI6IjNZbHJnTE1Ld0cvaisrQU4ya2Y3VkE9PSIsInZhbHVlIjoiZ0RlMVJLMjZGZ1h0VmNocWVHeVZYQWlHaUxDNkZxS0M4T0lLNS9UL1dKVHpsTFBqTXhEV1pYY1RHaTU2NUNtSmNLOEY4K0ZaWXloSjh1WUJIejBOeSthT0RRbko2aW9rVW4ydER2aE5GOVpDWERzU3BFNTR2ZjBMZ1plVkxLcngiLCJtYWMiOiIzMTkwZDAxM2JjOTdhMGI3MmYwN2NhMmVjMmRjMzJhYjQxNTNiNjU2Y2FlN2E3ZmY3ZGUzMGQxNGI0ZjZlYTNmIn0%3D");

    var formdata = new FormData();
    formdata.append("token", `${localStorage.getItem('token')}`);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };


    fetch(`https://api.eagleiq.online/api/admin/all-users`, requestOptions)
        .then(res => res.json())
        .then(data => dispatch({
            type: GET_ALL_USERS,
            payload: data
        }))

}

export const getLatestJob = () => {
    return async (dispatch) => {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");

        var formdata = new FormData();
        formdata.append("token", `${localStorage.getItem('token')}`);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch("https://api.eagleiq.online/api/admin/latest_job", requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log("🚀 ~ file: profileActions.js ~ line 101 ~ result", result)
                dispatch({ type: GET_LATEST_JOB, payload: result })
            })
            .catch(error => console.log('error', error));
    }
}


// Managers
export const getAllManagers = () => dispatch => {



    var myHeaders = new Headers();
    myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6Imh4bG1BdzZhc2NrZ1Z3NlhCNEtGSFE9PSIsInZhbHVlIjoicHdhNmxscUdJM1phUzVRTktqNU4wRnJGT0dISUMwOWJuYnhjUVI5ejZlQXozWUxCY050eXRyMEJDZk51WkszUklOcmplRGhGNkZqd0UrRzlBZkJzK2FBcDdERGtxV2pUKzJnallyR1AvL21IQ0h4Y014RzZpUmNSbEVjT25iZjMiLCJtYWMiOiI3MWM2NjcyZTA1NTIyMjllOTdmOWIwZGE3YjM5YTY2NDBhYWU1MTQ5N2M4YjQ2MTJjZjMxNzYxNDNkZDdlZTZiIn0%3D; laravel_session=eyJpdiI6IjNZbHJnTE1Ld0cvaisrQU4ya2Y3VkE9PSIsInZhbHVlIjoiZ0RlMVJLMjZGZ1h0VmNocWVHeVZYQWlHaUxDNkZxS0M4T0lLNS9UL1dKVHpsTFBqTXhEV1pYY1RHaTU2NUNtSmNLOEY4K0ZaWXloSjh1WUJIejBOeSthT0RRbko2aW9rVW4ydER2aE5GOVpDWERzU3BFNTR2ZjBMZ1plVkxLcngiLCJtYWMiOiIzMTkwZDAxM2JjOTdhMGI3MmYwN2NhMmVjMmRjMzJhYjQxNTNiNjU2Y2FlN2E3ZmY3ZGUzMGQxNGI0ZjZlYTNmIn0%3D");

    var formdata = new FormData();
    formdata.append("token", `${localStorage.getItem('token')}`);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };


    fetch(`https://api.eagleiq.online/api/admin/all-managers`, requestOptions)
        .then(res => res.json())
        .then(data => dispatch({
            type: GET_ALL_MANAGERS,
            payload: data
        }))

}
export const addNewManager = (formData, history) => dispatch => {
    dispatch({
        type: SET_IS_LOADING
    })

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6IjYvTlR3dUc3ZGJMaUxmRGJsTnk2R1E9PSIsInZhbHVlIjoiSTl6c29mQTg1bk9TWEtQWldlU3NmNDk3TnNJZVBVY2dOSFQ2TktIZFZrc2NJd2wyQXNxVlpYdk55UXQ4TTFLeVBjbXd5WVF6WUtLQTF3ci9na0F6a0hJN0ltMkdES21qUGx5b0hHbHJ2UlBhMGpMVU1acWVMS1k2RVZqWDVjRUUiLCJtYWMiOiIxNzVjZDZkNmRlNGM2OGJhMzIwNDc3Mzk1OTgxZjIwYzIwY2FmZTA0YmRmMzAyM2FkN2RmYjQ1NjU1MWZlZjM2In0%3D; laravel_session=eyJpdiI6Imd0S1hsWVBDejVJT1d3VVlNS1NrMVE9PSIsInZhbHVlIjoiRXpjNjBPbSsvRHROUkI0Z2R1ZDhsMGcyWG9GS2tZU3YzR3AwS3ltT0xOWm1CeURBNXZpaHREanFpTzdUZVFMTWlHd1ZNdFUxK2k1NTIxcmlIVjVGMXlyOHU0cEs3dG1ka2o5SW55ViszL2Y2blNuV0dxRDBxUUl6WWNFY0lZaUQiLCJtYWMiOiJhMzUyMGI4OWZkZjgwOTI0YjIwMjk1YmFlNzRjZjllODM0ZTU4YzFlZjE3OTdlZTg5YzM4ZTc5YTY5MzBkYmU1In0%3D");



    // formData.append("token", `${localStorage.getItem('token')}`);

    const token = {
        "token": localStorage.getItem('token')
    }

    formData = Object.assign(formData, token)
    formData = JSON.stringify(formData)
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formData,
        redirect: 'follow'
    };


    fetch(`https://api.eagleiq.online/api/admin/add-manager`, requestOptions)
        .then(res => res.json())
        .then(data => {
            if (data.error) {
                dispatch({
                    type: FETCH_ERROR
                })

                for (var key in data.error) {
                    if (data.error.hasOwnProperty(key)) {
                        console.info(data.error[key])
                    }
                }


            } else {
                dispatch({
                    type: ADD_NEW_MANAGER,
                    payload: data
                })
                dispatch({
                    type: SET_SWEET_ALERT
                })
                history.push('/')
                history.push('/admin/staff-database')
            }

        }

        )
}
export const deleteManager = (id, history) => dispatch => {
    dispatch({
        type: SET_IS_LOADING
    })


    var formData = new FormData();


    formData.append("token", `${localStorage.getItem('token')}`);
    formData.append("manager_id", id);

    fetch(`https://api.eagleiq.online/api/admin/delete-manager`, {
        method: 'POST',
        body: formData
    })
        .then(res => res.json())
        .then(data => {
            if (data.error) {
                dispatch({
                    type: FETCH_ERROR
                })

                for (var key in data.error) {
                    if (data.error.hasOwnProperty(key)) {
                        console.info(data.error[key])
                    }
                }


            } else {
                dispatch({
                    type: DELETE_MANAGER,
                    payload: data
                })
                dispatch({
                    type: SET_SWEET_ALERT
                })


                history.push('/')
                history.push('/admin/manager-database')

            }

        }

        )
}
export const updateManager = (formData, history) => dispatch => {
    dispatch({
        type: SET_IS_LOADING
    })


    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6IjYvTlR3dUc3ZGJMaUxmRGJsTnk2R1E9PSIsInZhbHVlIjoiSTl6c29mQTg1bk9TWEtQWldlU3NmNDk3TnNJZVBVY2dOSFQ2TktIZFZrc2NJd2wyQXNxVlpYdk55UXQ4TTFLeVBjbXd5WVF6WUtLQTF3ci9na0F6a0hJN0ltMkdES21qUGx5b0hHbHJ2UlBhMGpMVU1acWVMS1k2RVZqWDVjRUUiLCJtYWMiOiIxNzVjZDZkNmRlNGM2OGJhMzIwNDc3Mzk1OTgxZjIwYzIwY2FmZTA0YmRmMzAyM2FkN2RmYjQ1NjU1MWZlZjM2In0%3D; laravel_session=eyJpdiI6Imd0S1hsWVBDejVJT1d3VVlNS1NrMVE9PSIsInZhbHVlIjoiRXpjNjBPbSsvRHROUkI0Z2R1ZDhsMGcyWG9GS2tZU3YzR3AwS3ltT0xOWm1CeURBNXZpaHREanFpTzdUZVFMTWlHd1ZNdFUxK2k1NTIxcmlIVjVGMXlyOHU0cEs3dG1ka2o5SW55ViszL2Y2blNuV0dxRDBxUUl6WWNFY0lZaUQiLCJtYWMiOiJhMzUyMGI4OWZkZjgwOTI0YjIwMjk1YmFlNzRjZjllODM0ZTU4YzFlZjE3OTdlZTg5YzM4ZTc5YTY5MzBkYmU1In0%3D");



    // formData.append("token", `${localStorage.getItem('token')}`);

    const token = {
        "token": localStorage.getItem('token')
    }

    formData = Object.assign(formData, token)
    formData = JSON.stringify(formData)
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formData,
        redirect: 'follow'
    };


    fetch(`https://api.eagleiq.online/api/admin/update-manager`, requestOptions)
        .then(res => res.json())
        // .then(data => dispatch({
        //     type: UPDATE_MANAGER,
        //     payload: data
        // })

        .then(data => {
            if (data.error) {
                dispatch({
                    type: FETCH_ERROR
                })
                for (var key in data.error) {
                    if (data.error.hasOwnProperty(key)) {
                        console.info(data.error[key])
                    }
                }

            } else {
                dispatch({
                    type: UPDATE_MANAGER,
                    payload: data
                })
                dispatch({
                    type: SET_SWEET_ALERT
                })
                history.push('/')
                history.push('/admin/staff-database')
            }

        }

        )
}
// Staff
export const getAllStaff = () => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_GLOBAL_LOADER, payload: true })

        var myHeaders = new Headers();
        myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6Imh4bG1BdzZhc2NrZ1Z3NlhCNEtGSFE9PSIsInZhbHVlIjoicHdhNmxscUdJM1phUzVRTktqNU4wRnJGT0dISUMwOWJuYnhjUVI5ejZlQXozWUxCY050eXRyMEJDZk51WkszUklOcmplRGhGNkZqd0UrRzlBZkJzK2FBcDdERGtxV2pUKzJnallyR1AvL21IQ0h4Y014RzZpUmNSbEVjT25iZjMiLCJtYWMiOiI3MWM2NjcyZTA1NTIyMjllOTdmOWIwZGE3YjM5YTY2NDBhYWU1MTQ5N2M4YjQ2MTJjZjMxNzYxNDNkZDdlZTZiIn0%3D; laravel_session=eyJpdiI6IjNZbHJnTE1Ld0cvaisrQU4ya2Y3VkE9PSIsInZhbHVlIjoiZ0RlMVJLMjZGZ1h0VmNocWVHeVZYQWlHaUxDNkZxS0M4T0lLNS9UL1dKVHpsTFBqTXhEV1pYY1RHaTU2NUNtSmNLOEY4K0ZaWXloSjh1WUJIejBOeSthT0RRbko2aW9rVW4ydER2aE5GOVpDWERzU3BFNTR2ZjBMZ1plVkxLcngiLCJtYWMiOiIzMTkwZDAxM2JjOTdhMGI3MmYwN2NhMmVjMmRjMzJhYjQxNTNiNjU2Y2FlN2E3ZmY3ZGUzMGQxNGI0ZjZlYTNmIn0%3D");

        var formdata = new FormData();
        formdata.append("token", `${localStorage.getItem('token')}`);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };


        let data = await fetch(`https://api.eagleiq.online/api/admin/all-staff`, requestOptions)
            .then(res => res.json())

        dispatch({ type: GET_ALL_STAFF, payload: data })

        dispatch({ type: CHANGE_GLOBAL_LOADER, payload: false })
    }
}
export const addNewStaff = (formData, history) => dispatch => {
    dispatch({
        type: SET_IS_LOADING
    })

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6IjYvTlR3dUc3ZGJMaUxmRGJsTnk2R1E9PSIsInZhbHVlIjoiSTl6c29mQTg1bk9TWEtQWldlU3NmNDk3TnNJZVBVY2dOSFQ2TktIZFZrc2NJd2wyQXNxVlpYdk55UXQ4TTFLeVBjbXd5WVF6WUtLQTF3ci9na0F6a0hJN0ltMkdES21qUGx5b0hHbHJ2UlBhMGpMVU1acWVMS1k2RVZqWDVjRUUiLCJtYWMiOiIxNzVjZDZkNmRlNGM2OGJhMzIwNDc3Mzk1OTgxZjIwYzIwY2FmZTA0YmRmMzAyM2FkN2RmYjQ1NjU1MWZlZjM2In0%3D; laravel_session=eyJpdiI6Imd0S1hsWVBDejVJT1d3VVlNS1NrMVE9PSIsInZhbHVlIjoiRXpjNjBPbSsvRHROUkI0Z2R1ZDhsMGcyWG9GS2tZU3YzR3AwS3ltT0xOWm1CeURBNXZpaHREanFpTzdUZVFMTWlHd1ZNdFUxK2k1NTIxcmlIVjVGMXlyOHU0cEs3dG1ka2o5SW55ViszL2Y2blNuV0dxRDBxUUl6WWNFY0lZaUQiLCJtYWMiOiJhMzUyMGI4OWZkZjgwOTI0YjIwMjk1YmFlNzRjZjllODM0ZTU4YzFlZjE3OTdlZTg5YzM4ZTc5YTY5MzBkYmU1In0%3D");



    // formData.append("token", `${localStorage.getItem('token')}`);

    const token = {
        "token": localStorage.getItem('token')
    }

    formData = Object.assign(formData, token)
    formData = JSON.stringify(formData)
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formData,
        redirect: 'follow'
    };


    // formData.append("token", `${localStorage.getItem('token')}`);




    fetch(`https://api.eagleiq.online/api/admin/add-staff`, requestOptions)
        .then(res => res.json())
        .then(data => {
            if (data.error) {
                dispatch({
                    type: FETCH_ERROR
                })

                for (var key in data.error) {
                    if (data.error.hasOwnProperty(key)) {
                        console.info(data.error[key])
                    }
                }


            } else {
                dispatch({
                    type: ADD_NEW_STAFF,
                    payload: data
                })
                dispatch({
                    type: SET_SWEET_ALERT
                })

                history.push('/')

                history.push('/admin/staff-database')

            }

        }

        )
}
export const updateStaff = (formData, history) => dispatch => {
    dispatch({
        type: SET_IS_LOADING
    })

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6IjYvTlR3dUc3ZGJMaUxmRGJsTnk2R1E9PSIsInZhbHVlIjoiSTl6c29mQTg1bk9TWEtQWldlU3NmNDk3TnNJZVBVY2dOSFQ2TktIZFZrc2NJd2wyQXNxVlpYdk55UXQ4TTFLeVBjbXd5WVF6WUtLQTF3ci9na0F6a0hJN0ltMkdES21qUGx5b0hHbHJ2UlBhMGpMVU1acWVMS1k2RVZqWDVjRUUiLCJtYWMiOiIxNzVjZDZkNmRlNGM2OGJhMzIwNDc3Mzk1OTgxZjIwYzIwY2FmZTA0YmRmMzAyM2FkN2RmYjQ1NjU1MWZlZjM2In0%3D; laravel_session=eyJpdiI6Imd0S1hsWVBDejVJT1d3VVlNS1NrMVE9PSIsInZhbHVlIjoiRXpjNjBPbSsvRHROUkI0Z2R1ZDhsMGcyWG9GS2tZU3YzR3AwS3ltT0xOWm1CeURBNXZpaHREanFpTzdUZVFMTWlHd1ZNdFUxK2k1NTIxcmlIVjVGMXlyOHU0cEs3dG1ka2o5SW55ViszL2Y2blNuV0dxRDBxUUl6WWNFY0lZaUQiLCJtYWMiOiJhMzUyMGI4OWZkZjgwOTI0YjIwMjk1YmFlNzRjZjllODM0ZTU4YzFlZjE3OTdlZTg5YzM4ZTc5YTY5MzBkYmU1In0%3D");



    // formData.append("token", `${localStorage.getItem('token')}`);

    const token = {
        "token": localStorage.getItem('token')
    }

    formData = Object.assign(formData, token)
    formData = JSON.stringify(formData)

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formData,
        redirect: 'follow'
    };


    fetch(`https://api.eagleiq.online/api/admin/update-staff`, requestOptions)
        .then(res => res.json())
        // .then(data => dispatch({
        //     type: UPDATE_MANAGER,
        //     payload: data
        // })

        .then(data => {
            if (data.error) {
                dispatch({
                    type: FETCH_ERROR
                })
                for (var key in data.error) {
                    if (data.error.hasOwnProperty(key)) {
                        console.info(data.error[key])
                    }
                }
            } else {
                dispatch({
                    type: UPDATE_STAFF,
                    payload: data
                })
                dispatch({
                    type: SET_SWEET_ALERT
                })
                history.push('/')

                history.push('/admin/staff-database')

            }

        }

        )
}
export const deleteStaff = (id, history) => dispatch => {
    dispatch({
        type: SET_IS_LOADING
    })

    var formData = new FormData();


    formData.append("token", `${localStorage.getItem('token')}`);
    formData.append("staff_id", id);

    fetch(`https://api.eagleiq.online/api/admin/delete-staff`, {
        method: 'POST',
        body: formData
    })
        .then(res => res.json())
        .then(data => {
            if (data.error) {
                dispatch({
                    type: FETCH_ERROR
                })
                for (var key in data.error) {
                    if (data.error.hasOwnProperty(key)) {
                        console.info(data.error[key])
                    }
                }


            } else {
                dispatch({
                    type: DELETE_STAFF,
                    payload: data
                })
                dispatch({
                    type: SET_SWEET_ALERT
                })
                history.push('/')
                history.push('/admin/staff-database')

            }

        }

        )
}
// Clients
export const getAllClients = () => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_GLOBAL_LOADER, payload: true })

        var myHeaders = new Headers();
        myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6IndVSTRZR3VQRlkybGtQcnVKcUpuUVE9PSIsInZhbHVlIjoiVUg3ZnVubEQyNEFRWlpDYWdYNTIreWNIcmNkbDltWVpDaTNRWGNodXNmMVNCdm9zam5XWDlzVlo4R0JhSzZ6emdPRW1MYmlITmgwVDlZUFZVWXhxSHk4aXRjZFgxak1qbVIxVThyVlQwK1NKRzJzcHhRZ3QrZnlIUktOT1lJRFgiLCJtYWMiOiJhMmY1ZDA2OThlYWQ3YTRlMmE3YzAzZmZlZWQ1ZTU2ZTA3NjM4NjYyNTE0ODNjOTU2NmYwNGFmNzc1NWZmOWZiIn0%3D; laravel_session=eyJpdiI6IlhUZ3pXaXhUbGVIZjZUZVlMM0Y3dGc9PSIsInZhbHVlIjoiWWg5SE9XNkJ3OG1VcUs2RThwdU0wY3F5RkdTRmhsTzNzRURBVFlCVFBCWXQyUmRjNkNLUnVFQk03cjJXWGsrWXdrb3pPcHcxZjdLVDB1Q2hSb243VVhnMi9wRCtXeXhITjJPMkYzVE9QVXRoNi9aTE9YZTF3Mlh4ZFJhTEZxR0giLCJtYWMiOiJkMjZiMzY5YzZlMzMwYTdiYjgyNDRhYTRkMGQ5NzdjNzIyMjRiNWFjNThhMWQ3MzM0MDJhOTIxZWU1ZThmYzZhIn0%3D");
        var formdata = new FormData();
        formdata.append("token", `${localStorage.getItem('token')}`);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        let data = await fetch(`https://api.eagleiq.online/api/admin/all-clients`, requestOptions)
            .then(res => res.json())

        dispatch({ type: GET_ALL_CLIENTS, payload: data })
        dispatch(getAllLeaveRequests())
    }
}
export const addNewClient = (formData, history) => dispatch => {
    dispatch({ type: SET_IS_LOADING })


    formData.append("token", `${localStorage.getItem('token')}`);


    fetch(`https://api.eagleiq.online/api/admin/add-client`, {
        method: 'POST',
        body: formData
    })
        .then(res => res.json())
        .then(data => {
            if (data.error) {
                dispatch({
                    type: FETCH_ERROR
                })

                for (var key in data.error) {
                    if (data.error.hasOwnProperty(key)) {
                        console.info(data.error[key])
                    }
                }


            } else {
                dispatch({
                    type: ADD_NEW_CLIENT,
                    payload: data
                })
                dispatch({ type: SET_SWEET_ALERT })
                history.push('/')
                history.push('/admin/manage-clients')
            }

        }

        )
}
export const updateClient = (formData, history) => dispatch => {

    var myHeaders = new Headers();
    myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6InBEQm5yWWdpWFUzUEl4eUFDK2xvYWc9PSIsInZhbHVlIjoiN0NsdllKSlJYVzAyU1VBVjJodVJIcUFWTCtLaG0rZkxSYVZZbUp5K244Mmgyb01tditzRmF0VG1CeXdaS2JpRTZzMXVUai9BbmlubTZjcks1ZjRhNXZxakhjT1ZEN2Z5YUFvNDRtRHhNbFFGQkdZeHJjR1Z6V0NadGtrR1JVUTAiLCJtYWMiOiJhZmJhZTBiYTU0YWZhNjhlN2UxMGEwMWU5OTgyMDlmNDBlZDg5Mjc2MzFjYzEzNTFlNzI0MTIzNTFmM2IyZTc2In0%3D; laravel_session=eyJpdiI6IlE3MEFvV1Rhd2g2d3crM1phbVpTV1E9PSIsInZhbHVlIjoiNWZpS0NIeGRjQWd4dnN2bXRsMEtmSUwxcnhjWXkyRWZTRERTWkFvdElVU2M4cXI0REtKNEFmNlpQMTFQU3AzUzQrd1RtYTFNVFZwVVY0RzRpcko1T0o2dGJKbmxYb3ptcW1ZME8rQmYzcU1OejI5UkZpcXBONHpWVlBVcTBsV2giLCJtYWMiOiJkNzM0NGFjM2NkNjIwYWFjY2E2MjBkN2QxZDgyMzg1MTQ1YWI2ZGU2OTcxNzU1ZDc5OWE3NDdjNjE0YmM4MDgzIn0%3D");

    formData.append("token", `${localStorage.getItem('token')}`);


    fetch(`https://api.eagleiq.online/api/admin/update-client`, {
        method: 'POST',
        body: formData
    })
        .then(res => res.json())
        // .then(data => dispatch({
        //     type: UPDATE_MANAGER,
        //     payload: data
        // })

        .then(data => {
            if (data.error) {
                console.info(data.error)
            } else {
                dispatch({
                    type: UPDATE_CLIENT,
                    payload: data
                })
                history.push('/')
                history.push('/admin/manage-clients')
            }

        }

        )
}
export const deleteClient = (id, history) => dispatch => {
    dispatch({ type: SET_IS_LOADING })

    var formData = new FormData();


    formData.append("token", `${localStorage.getItem('token')}`);
    formData.append("client_id", id);

    fetch(`https://api.eagleiq.online/api/admin/delete-client`, {
        method: 'POST',
        body: formData
    })
        .then(res => res.json())
        .then(data => {
            if (data.error) {
                dispatch({
                    type: FETCH_ERROR
                })
                for (var key in data.error) {
                    if (data.error.hasOwnProperty(key)) {
                        console.info(data.error[key])
                    }
                }


            } else {
                dispatch({
                    type: DELETE_CLIENT,
                    payload: data
                })
                dispatch({
                    type: SET_SWEET_ALERT
                })
                history.push('/')
                history.push('/admin/manage-clients')
            }

        }

        )
}
// Sites

export const getAllSites = () => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_GLOBAL_LOADER, payload: true })

        var myHeaders = new Headers();
        myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6Ikc5bCtzZ0s1cHo3cEFqeDZ3dzNybVE9PSIsInZhbHVlIjoicWhpcXJCalRlWjB1d3VIaEZYOTVvcWl0dEpDVFhBZnZOTjlPTFZSeGRMUWVjSGlGQ0FxbDBDcnZ6Ni9NdGZwVURtMGxWT1B3aEgwbGJJQVdLVzdqeXcwdjEwS3lPdTB3UzM1T2xHZnFQMjhLOUhaSERTUmVtcmhpWk01bmwybkwiLCJtYWMiOiJjMGFiYzk4MTE5OGNlODc2ZDBmNDQ2NTkxNmM1ZWM1MzdjMDYxYTI3NmVlZGVkMzNiOTQ0YjMyNWRlZTE5ZTNkIn0%3D; laravel_session=eyJpdiI6ImRlVVJNak55c1ZicXJVWmU2d3pWWVE9PSIsInZhbHVlIjoibEQzR1BrSUZNN2dFd1ZVL1lROE4zZG5MOXRSQ0RXWStpQ3hHRFQ3ZGVkOS9HcDdJLzRXemVxZnFwdVh1aWdlYjdMck1MQWtqQ2RjaERmL0RBN0ZqM0dkN2t6WmdyZU96L2ROSEZmOGlxb1FMamtqZlJoSXZsTjdybWRTeGc4MW8iLCJtYWMiOiI0NDdlODExZDlhZTUxNWUyYmE0YjdmZjJkODFjM2I5MDlhOTRjZTgxNzlmZWY5MjVmNjZkOTljOWRlZDVlMzU3In0%3D");

        var formdata = new FormData();
        formdata.append("token", `${localStorage.getItem('token')}`);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        let data = await fetch(`https://api.eagleiq.online/api/client/my_sites`, requestOptions)
            .then(res => res.json())

        dispatch({ type: GET_ALL_SITES, payload: data })
        dispatch({ type: CHANGE_GLOBAL_LOADER, payload: false })
    }
}
export const getSiteProfile = (formdata) => dispatch => {



    var myHeaders = new Headers();
    myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6IndVSTRZR3VQRlkybGtQcnVKcUpuUVE9PSIsInZhbHVlIjoiVUg3ZnVubEQyNEFRWlpDYWdYNTIreWNIcmNkbDltWVpDaTNRWGNodXNmMVNCdm9zam5XWDlzVlo4R0JhSzZ6emdPRW1MYmlITmgwVDlZUFZVWXhxSHk4aXRjZFgxak1qbVIxVThyVlQwK1NKRzJzcHhRZ3QrZnlIUktOT1lJRFgiLCJtYWMiOiJhMmY1ZDA2OThlYWQ3YTRlMmE3YzAzZmZlZWQ1ZTU2ZTA3NjM4NjYyNTE0ODNjOTU2NmYwNGFmNzc1NWZmOWZiIn0%3D; laravel_session=eyJpdiI6IlhUZ3pXaXhUbGVIZjZUZVlMM0Y3dGc9PSIsInZhbHVlIjoiWWg5SE9XNkJ3OG1VcUs2RThwdU0wY3F5RkdTRmhsTzNzRURBVFlCVFBCWXQyUmRjNkNLUnVFQk03cjJXWGsrWXdrb3pPcHcxZjdLVDB1Q2hSb243VVhnMi9wRCtXeXhITjJPMkYzVE9QVXRoNi9aTE9YZTF3Mlh4ZFJhTEZxR0giLCJtYWMiOiJkMjZiMzY5YzZlMzMwYTdiYjgyNDRhYTRkMGQ5NzdjNzIyMjRiNWFjNThhMWQ3MzM0MDJhOTIxZWU1ZThmYzZhIn0%3D");


    formdata.append("token", `${localStorage.getItem('token')}`);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`https://api.eagleiq.online/api/admin/show-site`, requestOptions)
        .then(res => res.json())
        .then(data => dispatch({
            type: GET_SITE_PROFILE,
            payload: data
        }))

}
export const addNewSite = (formData, history) => dispatch => {

    dispatch({
        type: SET_IS_LOADING
    })


    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");



    // formData.append("token", `${localStorage.getItem('token')}`);

    const token = {
        "token": localStorage.getItem('token')
    }

    formData = Object.assign(formData, token)
    formData = JSON.stringify(formData)
    console.log(formData)
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formData,
        redirect: 'follow'
    };


    fetch(`https://api.eagleiq.online/api/admin/create-site`, requestOptions)
        .then(res => res.json())
        .then(data => {
            if (data.error) {
                dispatch({
                    type: FETCH_ERROR
                })

                for (var key in data.error) {
                    if (data.error.hasOwnProperty(key)) {
                        console.info(data.error[key])
                    }
                }


            } else {
                dispatch({
                    type: ADD_NEW_SITE,
                    payload: data
                })
                dispatch({
                    type: SET_SWEET_ALERT
                })
                history.push('/')
                history.push('/admin/manage-sites')

            }

        }

        )
}
export const updateSite = (formData, history) => dispatch => {
    dispatch({
        type: SET_IS_LOADING
    })
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const token = {
        "token": localStorage.getItem('token')
    }

    formData = Object.assign(formData, token)
    formData = JSON.stringify(formData)



    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formData,
        redirect: 'follow'
    };



    fetch(`https://api.eagleiq.online/api/admin/update-site`, requestOptions)
        .then(res => res.json())

        .then(data => {
            if (data.error) {
                dispatch({
                    type: FETCH_ERROR
                })
                for (var key in data.error) {
                    if (data.error.hasOwnProperty(key)) {
                        console.info(data.error[key])
                    }
                }
            } else {
                dispatch({
                    type: UPDATE_SITE,
                    payload: data
                })
                dispatch({
                    type: SET_SWEET_ALERT
                })
                history.push('/')
                history.push('/admin/manage-sites')
            }

        }

        )
}
export const deleteSite = (id, history) => dispatch => {
    dispatch({
        type: SET_IS_LOADING
    })
    var formData = new FormData();


    formData.append("token", `${localStorage.getItem('token')}`);
    formData.append("site_id", id);

    fetch(`https://api.eagleiq.online/api/admin/delete-site`, {
        method: 'POST',
        body: formData
    })
        .then(res => res.json())
        .then(data => {
            if (data.error) {
                dispatch({
                    type: FETCH_ERROR
                })
                for (var key in data.error) {
                    if (data.error.hasOwnProperty(key)) {
                        console.info(data.error[key])
                    }
                }


            } else {
                dispatch({
                    type: DELETE_SITE,
                    payload: data
                })
                dispatch({
                    type: SET_SWEET_ALERT
                })
                history.push('/')
                history.push('/admin/manage-sites')
            }

        }

        )
}
// Stocks
export const getAllStocks = () => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_GLOBAL_LOADER, payload: true })

        var myHeaders = new Headers();
        myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6Ikc5bCtzZ0s1cHo3cEFqeDZ3dzNybVE9PSIsInZhbHVlIjoicWhpcXJCalRlWjB1d3VIaEZYOTVvcWl0dEpDVFhBZnZOTjlPTFZSeGRMUWVjSGlGQ0FxbDBDcnZ6Ni9NdGZwVURtMGxWT1B3aEgwbGJJQVdLVzdqeXcwdjEwS3lPdTB3UzM1T2xHZnFQMjhLOUhaSERTUmVtcmhpWk01bmwybkwiLCJtYWMiOiJjMGFiYzk4MTE5OGNlODc2ZDBmNDQ2NTkxNmM1ZWM1MzdjMDYxYTI3NmVlZGVkMzNiOTQ0YjMyNWRlZTE5ZTNkIn0%3D; laravel_session=eyJpdiI6ImRlVVJNak55c1ZicXJVWmU2d3pWWVE9PSIsInZhbHVlIjoibEQzR1BrSUZNN2dFd1ZVL1lROE4zZG5MOXRSQ0RXWStpQ3hHRFQ3ZGVkOS9HcDdJLzRXemVxZnFwdVh1aWdlYjdMck1MQWtqQ2RjaERmL0RBN0ZqM0dkN2t6WmdyZU96L2ROSEZmOGlxb1FMamtqZlJoSXZsTjdybWRTeGc4MW8iLCJtYWMiOiI0NDdlODExZDlhZTUxNWUyYmE0YjdmZjJkODFjM2I5MDlhOTRjZTgxNzlmZWY5MjVmNjZkOTljOWRlZDVlMzU3In0%3D");

        var formdata = new FormData();
        formdata.append("token", `${localStorage.getItem('token')}`);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        let data = await fetch(`https://api.eagleiq.online/api/admin/all-stocks`, requestOptions)
            .then(res => res.json())

        dispatch({ type: GET_ALL_STOCKS, payload: data })
        dispatch({ type: CHANGE_GLOBAL_LOADER, payload: false })
    }
}
export const addNewStock = (formData, history) => dispatch => {
    dispatch({
        type: SET_IS_LOADING
    })


    formData.append("token", `${localStorage.getItem('token')}`);


    fetch(`https://api.eagleiq.online/api/admin/create-stock`, {
        method: 'POST',
        body: formData
    })
        .then(res => res.json())
        .then(data => {
            if (data.error) {
                dispatch({
                    type: FETCH_ERROR
                })

                for (var key in data.error) {
                    if (data.error.hasOwnProperty(key)) {
                        console.info(data.error[key])
                    }
                }


            } else {
                dispatch({
                    type: ADD_NEW_STOCK,
                    payload: data
                })
                dispatch({
                    type: SET_SWEET_ALERT
                })
                history.push('/')
                history.push('/admin/all-stocks')
            }

        }

        )
}
export const addStockCategory = (formData, history) => dispatch => {


    formData.append("token", `${localStorage.getItem('token')}`);


    fetch(`https://api.eagleiq.online/api/admin/create-stock-cat`, {
        method: 'POST',
        body: formData
    })
        .then(res => res.json())
        .then(data => {
            if (data.error) {

                for (var key in data.error) {
                    if (data.error.hasOwnProperty(key)) {
                        console.info(data.error[key])
                    }
                }


            } else {
                dispatch({
                    type: ADD_STOCK_CATEGORY,
                    payload: data
                })
                history.push('/')
                history.push('/admin/all-stocks')
            }

        }

        )
}
export const updateStock = (formData, history) => dispatch => {
    dispatch({
        type: SET_IS_LOADING
    })

    var myHeaders = new Headers();
    myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6Imlad3BlMTk2QnBjaE4rMXlMVzk3VEE9PSIsInZhbHVlIjoiYTZoUW40dXdTSnZQWmtsOHdweWJFcGUrQnFCUXpxdE5VRG9DMXVDQ2s2RElMU2ZmWFEzKzMyRUtreExQeGtXSWFvRTBaOGdlcUZEYUpmRTcrenFGdUl4L0ZyVTJqTGZZK0hBaUhUVURydDdtL2VrSDc1MWpUNG1RdGZrMjg4UHoiLCJtYWMiOiJkZWUyMTNiZTQ0NGU0NjFhY2JhOGVhZDY0MTIyZTJmODVlZGFjOGMyNWViNDE0YzljOGY0NTJlZDU2YjI4ZDZhIn0%3D; laravel_session=eyJpdiI6InJ3SXhEQ1NkSnBkSFhuZ0lUdHZKK0E9PSIsInZhbHVlIjoicGV0SFFBL0VDRU9yN1NabFc5dFhsaUxUVFcwb051alhMWFRPSW1veSt3L2NzWmpxdExtMGZCUHlZdTd3ZkNLREFPdlV2ckwwd0QrcFJ2VEFWT2VlVHpyNlFlR3YrdmpzeG00akxOWURoTWNzSXhvcnh4YndQV3gzU0NXZ09VUVMiLCJtYWMiOiIwMGZhNWM2M2Q1ZTg3Y2FjN2YzNzI5MjUxNTY3ZDBhNmEzMWIyZmQzZDc3YzAzN2IyN2RiZTg1OTQ3NTJiOTZiIn0%3D");

    formData.append("token", `${localStorage.getItem('token')}`);


    fetch(`https://api.eagleiq.online/api/admin/update-stock`, {
        method: 'POST',
        body: formData
    })
        .then(res => res.json())
        // .then(data => dispatch({
        //     type: UPDATE_MANAGER,
        //     payload: data
        // })

        .then(data => {
            if (data.error) {
                dispatch({
                    type: FETCH_ERROR
                })
                for (var key in data.error) {
                    if (data.error.hasOwnProperty(key)) {
                        console.info(data.error[key])
                    }
                }
            } else {
                dispatch({
                    type: UPDATE_STOCK,
                    payload: data
                })
                dispatch({
                    type: SET_SWEET_ALERT
                })
                history.push('/')
                history.push('/admin/all-stocks')
            }

        }

        )
}
export const deleteStock = (id, history) => dispatch => {
    dispatch({
        type: SET_IS_LOADING
    })

    var formData = new FormData();


    formData.append("token", `${localStorage.getItem('token')}`);
    formData.append("stock_id", id);

    fetch(`https://api.eagleiq.online/api/admin/delete-stock`, {
        method: 'POST',
        body: formData
    })
        .then(res => res.json())
        .then(data => {
            if (data.error) {

                for (var key in data.error) {
                    if (data.error.hasOwnProperty(key)) {
                        console.info(data.error[key])
                    }
                }
                dispatch({
                    type: FETCH_ERROR
                })

            } else {
                dispatch({
                    type: DELETE_STOCK,
                    payload: data
                })
                dispatch({
                    type: SET_SWEET_ALERT
                })
                history.push('/')
                history.push('/admin/all-stocks')
            }

        }

        )
}
export const getAllStockCategories = () => dispatch => {
    var myHeaders = new Headers();
    myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6Ikc5bCtzZ0s1cHo3cEFqeDZ3dzNybVE9PSIsInZhbHVlIjoicWhpcXJCalRlWjB1d3VIaEZYOTVvcWl0dEpDVFhBZnZOTjlPTFZSeGRMUWVjSGlGQ0FxbDBDcnZ6Ni9NdGZwVURtMGxWT1B3aEgwbGJJQVdLVzdqeXcwdjEwS3lPdTB3UzM1T2xHZnFQMjhLOUhaSERTUmVtcmhpWk01bmwybkwiLCJtYWMiOiJjMGFiYzk4MTE5OGNlODc2ZDBmNDQ2NTkxNmM1ZWM1MzdjMDYxYTI3NmVlZGVkMzNiOTQ0YjMyNWRlZTE5ZTNkIn0%3D; laravel_session=eyJpdiI6ImRlVVJNak55c1ZicXJVWmU2d3pWWVE9PSIsInZhbHVlIjoibEQzR1BrSUZNN2dFd1ZVL1lROE4zZG5MOXRSQ0RXWStpQ3hHRFQ3ZGVkOS9HcDdJLzRXemVxZnFwdVh1aWdlYjdMck1MQWtqQ2RjaERmL0RBN0ZqM0dkN2t6WmdyZU96L2ROSEZmOGlxb1FMamtqZlJoSXZsTjdybWRTeGc4MW8iLCJtYWMiOiI0NDdlODExZDlhZTUxNWUyYmE0YjdmZjJkODFjM2I5MDlhOTRjZTgxNzlmZWY5MjVmNjZkOTljOWRlZDVlMzU3In0%3D");

    var formdata = new FormData();
    formdata.append("token", `${localStorage.getItem('token')}`);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`https://api.eagleiq.online/api/admin/all-stock-cats`, requestOptions)
        .then(res => res.json())
        .then(data => dispatch({
            type: GET_ALL_STOCK_CATEGORIES,
            payload: data
        }))

}
// Stock Requests
export const getStockRequests = () => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_GLOBAL_LOADER, payload: true })
        console.log('action')
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6Ikc5bCtzZ0s1cHo3cEFqeDZ3dzNybVE9PSIsInZhbHVlIjoicWhpcXJCalRlWjB1d3VIaEZYOTVvcWl0dEpDVFhBZnZOTjlPTFZSeGRMUWVjSGlGQ0FxbDBDcnZ6Ni9NdGZwVURtMGxWT1B3aEgwbGJJQVdLVzdqeXcwdjEwS3lPdTB3UzM1T2xHZnFQMjhLOUhaSERTUmVtcmhpWk01bmwybkwiLCJtYWMiOiJjMGFiYzk4MTE5OGNlODc2ZDBmNDQ2NTkxNmM1ZWM1MzdjMDYxYTI3NmVlZGVkMzNiOTQ0YjMyNWRlZTE5ZTNkIn0%3D; laravel_session=eyJpdiI6ImRlVVJNak55c1ZicXJVWmU2d3pWWVE9PSIsInZhbHVlIjoibEQzR1BrSUZNN2dFd1ZVL1lROE4zZG5MOXRSQ0RXWStpQ3hHRFQ3ZGVkOS9HcDdJLzRXemVxZnFwdVh1aWdlYjdMck1MQWtqQ2RjaERmL0RBN0ZqM0dkN2t6WmdyZU96L2ROSEZmOGlxb1FMamtqZlJoSXZsTjdybWRTeGc4MW8iLCJtYWMiOiI0NDdlODExZDlhZTUxNWUyYmE0YjdmZjJkODFjM2I5MDlhOTRjZTgxNzlmZWY5MjVmNjZkOTljOWRlZDVlMzU3In0%3D");

        var formdata = new FormData();
        formdata.append("token", `${localStorage.getItem('token')}`);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        let data = await fetch(`https://api.eagleiq.online/api/admin/all-stock-request`, requestOptions)
            .then(res => res.json())

        dispatch({ type: GET_STOCK_REQUESTS, payload: data })
        dispatch({ type: CHANGE_GLOBAL_LOADER, payload: false })
    }
}
export const changeStockStatus = (id, status, history) => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_GLOBAL_LOADER, payload: true })

        var formData = new FormData();

        formData.append("id", id);
        formData.append("status", status);
        formData.append("token", `${localStorage.getItem('token')}`);


        let data = await fetch(`https://api.eagleiq.online/api/admin/update-stock-status`, { method: 'POST', body: formData })
            .then(res => res.json())

        if (data.error) {
            dispatch({
                type: FETCH_ERROR
            })
            for (var key in data.error) {
                if (data.error.hasOwnProperty(key)) {
                    console.info(data.error[key])
                }
            }
        } else {
            dispatch({
                type: CHANGE_STOCK_STATUS,
                payload: data
            })
            dispatch({
                type: SET_SWEET_ALERT
            })
            history.push('/')
            history.push('/admin/stock-requests')
        }
        dispatch({ type: CHANGE_GLOBAL_LOADER, payload: false })
    }
}
// Videos
export const getAllVideos = () => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_GLOBAL_LOADER, payload: true })

        var myHeaders = new Headers();
        myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6Ii85T0Zrb0FjRytLbUNQbjNGcFJXT1E9PSIsInZhbHVlIjoiOWtGb3R4VUJGcEd2V2JySlk1bmlaSC83K3M3b0Z0SU1rYUlpMWJsK29BRDVNOGZoSE1mUCt4UjFPUHV4dGJzajBkVE9sK3lScEhyQ0dSUmxHQUJFSit1ejBIVng0Q3ZqMDQ4Mkh6a2xvVEtTbm95amtwSnF2K2VCUlpCcFZDcDUiLCJtYWMiOiI1MTZjYjYxZmIxNTFkYmFkZWQzZGNjMmUwYWM3YjY5MDc0N2I3ZmYyNTViNTNkOGQ1MjJkOTMwMGY1Yjg0ZDM5In0%3D; laravel_session=eyJpdiI6IjlhRnZkVEJ1U0FyNFpHazhhSFVXYUE9PSIsInZhbHVlIjoiUlpxZ1JidCtZUlVtRm0wQUk5dXdGcWExdEdQL0lKSkdxLytzRVl3WExsdklHVENaTklXMzZ5MWlZYlloWktHU1NqMEV4V3ljd0E3SjhRb1l4N1lzUmxWdmx4N3JNQXEzd254WmtiQVFFYUFjYkg0SlU1UGFWcTJ2ZWIrT08vL3IiLCJtYWMiOiI5OWU0MDBjMGNiYTcwNGFlYWUyNWQ1OWExMjdlZjU1MTY0MjdmODNhOTk2NzdkY2RhMzM2NTgxYTlmYTQ0ZWQ3In0%3D");

        var formdata = new FormData();
        formdata.append("token", `${localStorage.getItem('token')}`);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        let data = await fetch(`https://api.eagleiq.online/api/admin/all-videos`, requestOptions)
            .then(res => res.json())

        dispatch({ type: GET_ALL_VIDEOS, payload: data })
        dispatch({ type: CHANGE_GLOBAL_LOADER, payload: false })
    }
}
export const getVideoCategories = () => dispatch => {
    var myHeaders = new Headers();
    myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6Ii85T0Zrb0FjRytLbUNQbjNGcFJXT1E9PSIsInZhbHVlIjoiOWtGb3R4VUJGcEd2V2JySlk1bmlaSC83K3M3b0Z0SU1rYUlpMWJsK29BRDVNOGZoSE1mUCt4UjFPUHV4dGJzajBkVE9sK3lScEhyQ0dSUmxHQUJFSit1ejBIVng0Q3ZqMDQ4Mkh6a2xvVEtTbm95amtwSnF2K2VCUlpCcFZDcDUiLCJtYWMiOiI1MTZjYjYxZmIxNTFkYmFkZWQzZGNjMmUwYWM3YjY5MDc0N2I3ZmYyNTViNTNkOGQ1MjJkOTMwMGY1Yjg0ZDM5In0%3D; laravel_session=eyJpdiI6IjlhRnZkVEJ1U0FyNFpHazhhSFVXYUE9PSIsInZhbHVlIjoiUlpxZ1JidCtZUlVtRm0wQUk5dXdGcWExdEdQL0lKSkdxLytzRVl3WExsdklHVENaTklXMzZ5MWlZYlloWktHU1NqMEV4V3ljd0E3SjhRb1l4N1lzUmxWdmx4N3JNQXEzd254WmtiQVFFYUFjYkg0SlU1UGFWcTJ2ZWIrT08vL3IiLCJtYWMiOiI5OWU0MDBjMGNiYTcwNGFlYWUyNWQ1OWExMjdlZjU1MTY0MjdmODNhOTk2NzdkY2RhMzM2NTgxYTlmYTQ0ZWQ3In0%3D");

    var formdata = new FormData();
    formdata.append("token", `${localStorage.getItem('token')}`);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`https://api.eagleiq.online/api/admin/all-video-cats`, requestOptions)
        .then(res => res.json())
        .then(data => dispatch({
            type: GET_VIDEO_CATEGORIES,
            payload: data
        }))

}
export const addNewVideo = (formData, history) => dispatch => {

    dispatch(
        {
            type: SET_IS_LOADING
        }
    )



    formData.append("token", `${localStorage.getItem('token')}`);


    fetch(`https://api.eagleiq.online/api/admin/create-video`, {
        method: 'POST',
        body: formData
    })
        .then(res => res.json())
        .then(data => {
            if (data.error) {
                dispatch({
                    type: FETCH_ERROR
                })

                for (var key in data.error) {
                    if (data.error.hasOwnProperty(key)) {
                        console.info(data.error[key])
                    }
                }


            } else {
                dispatch({
                    type: ADD_NEW_VIDEO,
                    payload: data
                })
                dispatch({
                    type: SET_SWEET_ALERT
                })
                history.push('/')
                history.push('/admin/VideoTutorials')
            }

        }

        )
}
export const addVideoCategory = (formData, history) => dispatch => {


    formData.append("token", `${localStorage.getItem('token')}`);


    fetch(`https://api.eagleiq.online/api/admin/create-video-cat`, {
        method: 'POST',
        body: formData
    })
        .then(res => res.json())
        .then(data => {
            if (data.error) {
                dispatch({
                    type: FETCH_ERROR
                })

                for (var key in data.error) {
                    if (data.error.hasOwnProperty(key)) {
                        console.info(data.error[key])
                    }
                }


            } else {
                dispatch({
                    type: ADD_VIDEO_CATEGORY,
                    payload: data
                })
                dispatch({
                    type: SET_SWEET_ALERT
                })
                history.push('/')
                history.push('/admin/VideoTutorials')
            }

        }

        )
}
export const updateVideo = (formData, history) => dispatch => {
    dispatch(
        {
            type: SET_IS_LOADING
        }
    )

    var myHeaders = new Headers();
    myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6Imlad3BlMTk2QnBjaE4rMXlMVzk3VEE9PSIsInZhbHVlIjoiYTZoUW40dXdTSnZQWmtsOHdweWJFcGUrQnFCUXpxdE5VRG9DMXVDQ2s2RElMU2ZmWFEzKzMyRUtreExQeGtXSWFvRTBaOGdlcUZEYUpmRTcrenFGdUl4L0ZyVTJqTGZZK0hBaUhUVURydDdtL2VrSDc1MWpUNG1RdGZrMjg4UHoiLCJtYWMiOiJkZWUyMTNiZTQ0NGU0NjFhY2JhOGVhZDY0MTIyZTJmODVlZGFjOGMyNWViNDE0YzljOGY0NTJlZDU2YjI4ZDZhIn0%3D; laravel_session=eyJpdiI6InJ3SXhEQ1NkSnBkSFhuZ0lUdHZKK0E9PSIsInZhbHVlIjoicGV0SFFBL0VDRU9yN1NabFc5dFhsaUxUVFcwb051alhMWFRPSW1veSt3L2NzWmpxdExtMGZCUHlZdTd3ZkNLREFPdlV2ckwwd0QrcFJ2VEFWT2VlVHpyNlFlR3YrdmpzeG00akxOWURoTWNzSXhvcnh4YndQV3gzU0NXZ09VUVMiLCJtYWMiOiIwMGZhNWM2M2Q1ZTg3Y2FjN2YzNzI5MjUxNTY3ZDBhNmEzMWIyZmQzZDc3YzAzN2IyN2RiZTg1OTQ3NTJiOTZiIn0%3D");

    formData.append("token", `${localStorage.getItem('token')}`);


    fetch(`https://api.eagleiq.online/api/admin/update-video`, {
        method: 'POST',
        body: formData
    })
        .then(res => res.json())
        .then(data => {



            if (data.error) {
                dispatch({
                    type: FETCH_ERROR
                })
                for (var key in data.error) {
                    if (data.error.hasOwnProperty(key)) {
                        console.info(data.error[key])
                    }
                }
            } else {


                dispatch({
                    type: UPDATE_VIDEO,
                    payload: data
                })
                dispatch({
                    type: SET_SWEET_ALERT
                })
                history.push('/')
                history.push('/admin/VideoTutorials')
            }

        }

        )
}
export const deleteVideo = (id, history) => dispatch => {
    dispatch(
        {
            type: SET_IS_LOADING
        }
    )

    var formData = new FormData();


    formData.append("token", `${localStorage.getItem('token')}`);
    formData.append("video_id", id);

    fetch(`https://api.eagleiq.online/api/admin/delete-video`, {
        method: 'POST',
        body: formData
    })
        .then(res => res.json())
        .then(data => {
            if (data.error) {
                dispatch({
                    type: FETCH_ERROR
                })
                for (var key in data.error) {
                    if (data.error.hasOwnProperty(key)) {
                        console.info(data.error[key])
                    }
                }


            } else {
                dispatch({
                    type: DELETE_VIDEO,
                    payload: data
                })
                dispatch({
                    type: SET_SWEET_ALERT
                })
                history.push('/')
                history.push('/admin/VideoTutorials')
            }

        }

        )
}
// JOBS
export const getAllJobs = () => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_GLOBAL_LOADER, payload: true })

        var myHeaders = new Headers();
        myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6Ii85T0Zrb0FjRytLbUNQbjNGcFJXT1E9PSIsInZhbHVlIjoiOWtGb3R4VUJGcEd2V2JySlk1bmlaSC83K3M3b0Z0SU1rYUlpMWJsK29BRDVNOGZoSE1mUCt4UjFPUHV4dGJzajBkVE9sK3lScEhyQ0dSUmxHQUJFSit1ejBIVng0Q3ZqMDQ4Mkh6a2xvVEtTbm95amtwSnF2K2VCUlpCcFZDcDUiLCJtYWMiOiI1MTZjYjYxZmIxNTFkYmFkZWQzZGNjMmUwYWM3YjY5MDc0N2I3ZmYyNTViNTNkOGQ1MjJkOTMwMGY1Yjg0ZDM5In0%3D; laravel_session=eyJpdiI6IjlhRnZkVEJ1U0FyNFpHazhhSFVXYUE9PSIsInZhbHVlIjoiUlpxZ1JidCtZUlVtRm0wQUk5dXdGcWExdEdQL0lKSkdxLytzRVl3WExsdklHVENaTklXMzZ5MWlZYlloWktHU1NqMEV4V3ljd0E3SjhRb1l4N1lzUmxWdmx4N3JNQXEzd254WmtiQVFFYUFjYkg0SlU1UGFWcTJ2ZWIrT08vL3IiLCJtYWMiOiI5OWU0MDBjMGNiYTcwNGFlYWUyNWQ1OWExMjdlZjU1MTY0MjdmODNhOTk2NzdkY2RhMzM2NTgxYTlmYTQ0ZWQ3In0%3D");
        var formdata = new FormData();
        formdata.append("token", `${localStorage.getItem('token')}`);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };
        let data = await fetch(`https://api.eagleiq.online/api/client/my_jobs`, requestOptions).then(res => res.json())

        dispatch({ type: GET_ALL_JOBS, payload: data })

        dispatch({ type: CHANGE_GLOBAL_LOADER, payload: false })
    }
}

export const addNewJob = (raw, history) => dispatch => {

    dispatch({
        type: IS_CREATING_JOB
    })


    // formData.append("token", `${localStorage.getItem('token')}`);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6IkZlTHJQa0w1cXdNb1hNcjc4cEk3anc9PSIsInZhbHVlIjoicFhDTlg2TWpKdFhJZE5pYkk2ZVB6ZE5oL1o4V1EwVExkNURlYWNlODlvT1NlS3JXcnMvbjZMb3VkY3d5Z1lwaTQwUUdLN2sySGFFVXl1VmZ0QnVYZlM2aHl6OHREeEE0Yk04MnIwcTlkajhtamdZWTh0blY3SzR5MTdrMXF5L3giLCJtYWMiOiIyNTBmZjg1YmNmNzY1NjAyYTI4NTY1MzU0ODM3M2U3YTRkNGY2MzgxNzVlMGNiZjM4MmJlODY1NzBkNTIzMDE4In0%3D; laravel_session=eyJpdiI6IlVZN1k1ekJlclBDM1NMQ3dHc2V5OWc9PSIsInZhbHVlIjoiYWQ0dXlBRnlBZDdaSkJYeTNsck1EeHlhY3A3OGVKMVJJa3dwMFpHQmxIMGJTY05RT3FPSUtwNVpRNFBnS05SeUxMK1VLSUluN1ExWFRPRnA1OFRDODdNdUUwV3dpNU5UVjdkR3BIN3FLSTJ1UXJvRC9JRFdvUysxQkRTL1hSaEoiLCJtYWMiOiI5ODQ4NzkyYmNhODljMGQ3MTU3YWI1MjljODA5NWNkMTc2NjQ0ZDQ5MDZkMjAwZTA1NjM1ZGU5NGJjNjlmODE3In0%3D");



    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };


    fetch("https://api.eagleiq.online/api/admin/create-job", requestOptions)
        .then(res => res.json())
        .then(data => {
            if (data.error) {
                dispatch({
                    type: FETCH_ERROR
                })
                for (var key in data.error) {
                    if (data.error.hasOwnProperty(key)) {
                        console.info(data.error[key])
                    }
                }


            } else {
                dispatch({
                    type: ADD_NEW_JOB,
                    payload: data
                })
                dispatch({
                    type: SET_SWEET_ALERT
                })
                history.push('/')
                history.push('/admin/manage-jobs')
            }

        })
        // .then(result => console.log(result))
        .catch(error => console.log('error', error));
}
export const deleteJob = (id, history) => dispatch => {

    var formData = new FormData();


    formData.append("token", `${localStorage.getItem('token')}`);
    formData.append("job_id", id);

    fetch(`https://api.eagleiq.online/api/admin/delete-job`, {
        method: 'POST',
        body: formData
    })
        .then(res => res.json())
        .then(data => {
            if (data.error) {
                // console.log(data.error)
                for (var key in data.error) {
                    if (data.error.hasOwnProperty(key)) {
                        console.info(data.error[key])
                    }
                }


            } else {
                dispatch({
                    type: DELETE_JOB,
                    payload: data
                })
                history.push('/')
                history.push('/admin/manage-jobs')
            }

        }

        )
}
export const uploadAnyFile = (formdata, history) => dispatch => {
    console.log('action hit')

    formdata.append("token", `${localStorage.getItem('token')}`);
    dispatch({
        type: SET_IS_LOADING
    })


    fetch(`https://api.eagleiq.online/api/admin/upload-file`, {
        method: 'POST',
        body: formdata
    })
        .then(res => res.json())
        .then(data => {
            if (data.error) {
                dispatch({
                    type: FETCH_ERROR
                })

                for (var key in data.error) {
                    if (data.error.hasOwnProperty(key)) {
                        console.info(data.error[key])
                    }
                }


            } else {
                dispatch({
                    type: UPLOAD_FILE,
                    payload: data
                })
                dispatch({
                    type: SET_SWEET_ALERT
                })
                // history.push('/')
                // history.push('/admin/manage-jobs')
            }

        }

        )
}
// Leave Requests
export const getAllLeaveRequests = () => {
    return async (dispatch) => {
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6Ii85T0Zrb0FjRytLbUNQbjNGcFJXT1E9PSIsInZhbHVlIjoiOWtGb3R4VUJGcEd2V2JySlk1bmlaSC83K3M3b0Z0SU1rYUlpMWJsK29BRDVNOGZoSE1mUCt4UjFPUHV4dGJzajBkVE9sK3lScEhyQ0dSUmxHQUJFSit1ejBIVng0Q3ZqMDQ4Mkh6a2xvVEtTbm95amtwSnF2K2VCUlpCcFZDcDUiLCJtYWMiOiI1MTZjYjYxZmIxNTFkYmFkZWQzZGNjMmUwYWM3YjY5MDc0N2I3ZmYyNTViNTNkOGQ1MjJkOTMwMGY1Yjg0ZDM5In0%3D; laravel_session=eyJpdiI6IjlhRnZkVEJ1U0FyNFpHazhhSFVXYUE9PSIsInZhbHVlIjoiUlpxZ1JidCtZUlVtRm0wQUk5dXdGcWExdEdQL0lKSkdxLytzRVl3WExsdklHVENaTklXMzZ5MWlZYlloWktHU1NqMEV4V3ljd0E3SjhRb1l4N1lzUmxWdmx4N3JNQXEzd254WmtiQVFFYUFjYkg0SlU1UGFWcTJ2ZWIrT08vL3IiLCJtYWMiOiI5OWU0MDBjMGNiYTcwNGFlYWUyNWQ1OWExMjdlZjU1MTY0MjdmODNhOTk2NzdkY2RhMzM2NTgxYTlmYTQ0ZWQ3In0%3D");

        var formdata = new FormData();
        formdata.append("token", `${localStorage.getItem('token')}`);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        let data = await fetch(`https://api.eagleiq.online/api/admin/leave-requests`, requestOptions)
            .then(res => res.json())

        dispatch({ type: GET_ALL_LEAVE_REQUESTS, payload: data })

        dispatch({ type: CHANGE_GLOBAL_LOADER, payload: false })
    }
}
export const changeLeaveStatus = (id, status, history) => dispatch => {


    dispatch(
        {
            type: SET_IS_LOADING
        }
    )

    var formData = new FormData();

    formData.append("leave_id", id);
    formData.append("status", status);
    formData.append("token", `${localStorage.getItem('token')}`);


    fetch(`https://api.eagleiq.online/api/admin/update-leave-status`, {
        method: 'POST',
        body: formData
    })
        .then(res => res.json())
        .then(data => {
            if (data.error) {
                dispatch({
                    type: FETCH_ERROR
                })

                for (var key in data.error) {
                    if (data.error.hasOwnProperty(key)) {
                        console.info(data.error[key])
                    }
                }


            } else {
                dispatch({
                    type: CHANGE_LEAVE_STATUS,
                    payload: data
                })
                dispatch({
                    type: SET_SWEET_ALERT
                })
                history.push('/')
                history.push('/admin/leave-requests')
            }

        }

        )
}
// Timesheets
export const getTimesheets = () => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_GLOBAL_LOADER, payload: true })

        var myHeaders = new Headers();
        myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6Ii85T0Zrb0FjRytLbUNQbjNGcFJXT1E9PSIsInZhbHVlIjoiOWtGb3R4VUJGcEd2V2JySlk1bmlaSC83K3M3b0Z0SU1rYUlpMWJsK29BRDVNOGZoSE1mUCt4UjFPUHV4dGJzajBkVE9sK3lScEhyQ0dSUmxHQUJFSit1ejBIVng0Q3ZqMDQ4Mkh6a2xvVEtTbm95amtwSnF2K2VCUlpCcFZDcDUiLCJtYWMiOiI1MTZjYjYxZmIxNTFkYmFkZWQzZGNjMmUwYWM3YjY5MDc0N2I3ZmYyNTViNTNkOGQ1MjJkOTMwMGY1Yjg0ZDM5In0%3D; laravel_session=eyJpdiI6IjlhRnZkVEJ1U0FyNFpHazhhSFVXYUE9PSIsInZhbHVlIjoiUlpxZ1JidCtZUlVtRm0wQUk5dXdGcWExdEdQL0lKSkdxLytzRVl3WExsdklHVENaTklXMzZ5MWlZYlloWktHU1NqMEV4V3ljd0E3SjhRb1l4N1lzUmxWdmx4N3JNQXEzd254WmtiQVFFYUFjYkg0SlU1UGFWcTJ2ZWIrT08vL3IiLCJtYWMiOiI5OWU0MDBjMGNiYTcwNGFlYWUyNWQ1OWExMjdlZjU1MTY0MjdmODNhOTk2NzdkY2RhMzM2NTgxYTlmYTQ0ZWQ3In0%3D");

        var formdata = new FormData();
        formdata.append("token", `${localStorage.getItem('token')}`);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        let data = await fetch(`https://api.eagleiq.online/api/admin/time-sheet`, requestOptions)
            .then(res => res.json())

        dispatch({ type: GET_ALL_TIMESHEETS, payload: data })

        dispatch({ type: CHANGE_GLOBAL_LOADER, payload: false })
    }
}
// Admin Profile
export const updateAdminProfile = (formdata, history) => dispatch => {
    dispatch(
        {
            type: SET_IS_LOADING
        }
    )
    var myHeaders = new Headers();
    myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6IlFlMHhWMEZZN0hONGdrbnphdzJoQ3c9PSIsInZhbHVlIjoiVHZXcnp1Z3ZrUjBSZWhvd0NIOXhxUG5GN01hOEVFcXNXTEJXYUsyVXhoTEN6cXF5N1RmeVpCK3lQdkIvejVLSld3cWNvSnptT1RvR21rWFJPUDI0ZEZYREMrM3pYdkNZdzBvYXBDSkZQUUpKU04yMG1nQlZtcG1pWGJJWjZaWnoiLCJtYWMiOiJiMzE3OTQ2NzExODAwZjNkZmUyYjg2ZWYzOTRiMjRiZTcwNGE0OGEyMjJiNWJkMjgzZTM5NDBiZjkwZTY1YjgwIn0%3D; laravel_session=eyJpdiI6Im9WTTJnWUZjNHBTMW95YUsyT3hIQnc9PSIsInZhbHVlIjoiL1U0c0NVK05aZUwzQktZdkpPcTFXNStKZTFWSU5qRWVnL1d2SnYyRFJvamc0ZXROYklmbURYSFY3K0prekQ0MEd3YlQ2ekc2UFhZVCs4VDBreDlxTTFWM2VkRlBmaTlkREtjQTJtSEZGUEQzQXR0OEVmNDRUTmdHRXFEYUlvVEMiLCJtYWMiOiI2MjJkZjc5ZWMzOTgyMzQxMzM2YmU4YTc0Zjg0ZTI5YzFiODhiMDFmYmExYmNkNTE5ODk0MTU3MjA3YWQ5MDllIn0%3D");

    formdata.append("token", `${localStorage.getItem('token')}`);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`https://api.eagleiq.online/api/client/update-profile`, requestOptions)
        .then(res => res.json())
        .then(data => {
            if (data.error) {
                dispatch({
                    type: FETCH_ERROR
                })
                for (var key in data.error) {
                    if (data.error.hasOwnProperty(key)) {
                        console.info(data.error[key])
                    }
                }


            } else {
                dispatch({
                    type: UPDATE_ADMIN_PROFILE,
                    payload: data
                })
                dispatch({
                    type: SET_SWEET_ALERT
                })
                history.push('/')
                history.push('/admin/edit-profile')
            }

        }

        )
    // .then(data => dispatch({
    //     type: UPDATE_ADMIN_PROFILE,
    //     payload: data
    // }))

}

export const updateProfilePicture = (formdata, history) => dispatch => {
    var myHeaders = new Headers();
    myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6IlFlMHhWMEZZN0hONGdrbnphdzJoQ3c9PSIsInZhbHVlIjoiVHZXcnp1Z3ZrUjBSZWhvd0NIOXhxUG5GN01hOEVFcXNXTEJXYUsyVXhoTEN6cXF5N1RmeVpCK3lQdkIvejVLSld3cWNvSnptT1RvR21rWFJPUDI0ZEZYREMrM3pYdkNZdzBvYXBDSkZQUUpKU04yMG1nQlZtcG1pWGJJWjZaWnoiLCJtYWMiOiJiMzE3OTQ2NzExODAwZjNkZmUyYjg2ZWYzOTRiMjRiZTcwNGE0OGEyMjJiNWJkMjgzZTM5NDBiZjkwZTY1YjgwIn0%3D; laravel_session=eyJpdiI6Im9WTTJnWUZjNHBTMW95YUsyT3hIQnc9PSIsInZhbHVlIjoiL1U0c0NVK05aZUwzQktZdkpPcTFXNStKZTFWSU5qRWVnL1d2SnYyRFJvamc0ZXROYklmbURYSFY3K0prekQ0MEd3YlQ2ekc2UFhZVCs4VDBreDlxTTFWM2VkRlBmaTlkREtjQTJtSEZGUEQzQXR0OEVmNDRUTmdHRXFEYUlvVEMiLCJtYWMiOiI2MjJkZjc5ZWMzOTgyMzQxMzM2YmU4YTc0Zjg0ZTI5YzFiODhiMDFmYmExYmNkNTE5ODk0MTU3MjA3YWQ5MDllIn0%3D");

    formdata.append("token", `${localStorage.getItem('token')}`);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`https://api.eagleiq.online/api/client/update-pic`, requestOptions)
        .then(res => res.json())
        .then(data => {
            if (data.error) {
                dispatch({
                    type: FETCH_ERROR
                })
                for (var key in data.error) {
                    if (data.error.hasOwnProperty(key)) {
                        console.info(data.error[key])
                    }
                }

            } else {
                history.push('/')
                history.push('/admin/edit-profile')
            }
        }
        )
}

// USER PROFILE
export const getUserProfile = () => {

    return async (dispatch) => {
        dispatch({ type: CHANGE_GLOBAL_LOADER, payload: true })
        var formdata = new FormData();
        formdata.append("token", localStorage.getItem('token'));

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        fetch("https://api.eagleiq.online/api/client/profile", requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log("🚀 ~ file: profileActions.js ~ line 1691 ~ return ~ data", data)
                dispatch({ type: GET_USER_PROFILE, payload: data })
                dispatch({ type: CHANGE_GLOBAL_LOADER, payload: false })
            })
            .catch(error => console.log('error', error));
    }

}

// UPDATE USER PROFILE
export const updateUserProfile = (val) => {
    console.log("🚀 ~ file: profileActions.js ~ line 1704 ~ updateUserProfile ~ val", val)

    return async (dispatch) => {
        dispatch({ type: CHANGE_GLOBAL_LOADER, payload: true })
        var formdata = new FormData();
        formdata.append("token", localStorage.getItem('token'));
        formdata.append("fname", val.fname);
        formdata.append("lname", val.lname);
        formdata.append("address", val.address);
        formdata.append("post_code", val.post_code);
        formdata.append("phone_no", val.phone_no);
        formdata.append("mobile_no", val.mobile_no);
        formdata.append("email", val.email);
        formdata.append("password", val.password);
        formdata.append("pic", val.pic);
        formdata.append("status", val.status);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        fetch("https://api.eagleiq.online/api/client/update_profile", requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log("🚀 ~ file: profileActions.js ~ line 1730 ~ return ~ data", data)
                dispatch({ type: UPDATE_USER_PROFILE, payload: data })
                dispatch({ type: CHANGE_GLOBAL_LOADER, payload: false })
            })
            .catch(error => console.log('error', error));
    }

}

// Dashboard Data
export const getDashboardData = () => {

    return async (dispatch) => {
        dispatch({ type: CHANGE_GLOBAL_LOADER, payload: true })
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");

        var formdata = new FormData();
        formdata.append("token", localStorage.getItem('token'));

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch("https://api.eagleiq.online/api/client/dashboard", requestOptions)
            .then(response => response.json())
            .then(data => {
                dispatch({ type: GET_DASHBOARD_DATA, payload: data })
                dispatch({ type: CHANGE_GLOBAL_LOADER, payload: false })
            })
            .catch(error => console.log('error', error));
    }

}
export const removeSweetAlert = () => dispatch => {
    dispatch({
        type: REMOVE_SWEET_ALERT
    })

}