
import Dashboard from "views/Dashboard/Dashboard.js";
import Notes from "views/Forms/Notes";
import EditProfile from "views/Pages/EditProfile";
import LoginPage from "views/Pages/LoginPage";
import MyProfile from "views/Pages/MyProfile.js";
import BookingRecords from "views/Tables/BookingRecords";
import Invoice from "views/Tables/Invoice";
import ManageJobs from "views/Tables/ManageJobs";
import ManageSite from "views/Tables/ManageSite";


let routes = [
  {
    path: "/login-page",
    name: "Login Page",
    short: "Login",
    mini: "LP",
    component: LoginPage,
    invisible: true,
    layout: "/auth",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "now-ui-icons design_app",
    component: Dashboard,
    layout: "/admin",
    private: true
  },
  {
    path: "/jobs",
    name: "Jobs",
    icon: "fas fa-thumbtack",
    component: ManageJobs,
    layout: "/admin",
    private: true
  },
  {
    path: "/sites",
    name: "Sites",
    icon: "fas fa-building",
    component: ManageSite,
    layout: "/admin",
    private: true
  },
  // {
  //   path: "/manager-database",
  //   name: "Manager Database",
  //   icon: "now-ui-icons objects_diamond",
  //   component: ManagerDatabase,
  //   layout: "/admin",
  // },
  // {
  //   path: "/staff-database",
  //   name: "Staff Database",
  //   icon: "now-ui-icons objects_diamond",
  //   component: StaffDatabase,
  //   layout: "/admin",
  // },
  // {
  //   path: "/add-new-task",
  //   name: "Task Specifications",
  //   icon: "now-ui-icons objects_diamond",
  //   invisible: true,
  //   component: AddNewTask,
  //   layout: "/admin",
  // },
  // {
  //   path: "/add-new-area",
  //   name: "Task Specifications",
  //   icon: "now-ui-icons objects_diamond",
  //   invisible: true,
  //   component: AddNewArea,
  //   layout: "/admin",
  // },


  // {
  //   path: "/manage-jobs",
  //   name: "Manage Jobs",
  //   icon: "now-ui-icons objects_diamond",
  //   component: ManageJobs,
  //   layout: "/admin",
  // },
  // {
  //   path: "/manage-sites",
  //   name: "Manage Sites",
  //   icon: "now-ui-icons objects_diamond",
  //   component: ManageSite,
  //   layout: "/admin",
  // },
  // {
  //   path: "/manage-clients",
  //   name: "Client Database",
  //   icon: "now-ui-icons objects_diamond",
  //   component: ManageClients,
  //   layout: "/admin",
  // },
  // {
  //   path: "/stock-requests",
  //   name: "Stock Management",
  //   icon: "now-ui-icons objects_diamond",
  //   component: StockRequests,
  //   layout: "/admin",
  // },
  // {
  //   path: "/timesheets",
  //   name: "TimeSheets",
  //   icon: "now-ui-icons objects_diamond",
  //   component: TimeSheets,
  //   layout: "/admin",
  // },
  // {
  //   path: "/create-site",
  //   name: "Create Site",
  //   icon: "now-ui-icons objects_diamond",
  //   invisible: true,
  //   component: CreateSite,
  //   layout: "/admin",
  // },
  // {
  //   path: "/add-client",
  //   name: "Add Client",
  //   icon: "now-ui-icons objects_diamond",
  //   invisible: true,
  //   component: AddClient,
  //   layout: "/admin",
  // },
  // {
  //   path: "/add-manager",
  //   name: "Add Manager",
  //   icon: "now-ui-icons objects_diamond",
  //   invisible: true,
  //   component: AddManager,
  //   layout: "/admin",
  // },
  // {
  //   path: "/add-job",
  //   name: "Add New Job",
  //   icon: "now-ui-icons objects_diamond",
  //   invisible: true,
  //   component: AddNewJob,
  //   layout: "/admin",
  // },
  {
    path: "/edit-profile",
    name: "Profile",
    mini: "UP",
    component: EditProfile,
    invisible: true,
    layout: "/admin",
  },
  {
    path: "/my-profile",
    name: "Profile",
    mini: "UP",
    component: MyProfile,
    invisible: true,
    layout: "/admin",
  },
  {
    path: "/notes",
    name: "Notes",
    icon: "now-ui-icons objects_diamond",
    component: Notes,
    layout: "/admin",
    invisible: true,
  },
  // {
  //   path: "/charts",
  //   name: "Charts",
  //   icon: "now-ui-icons business_chart-pie-36",
  //   component: Charts,
  //   layout: "/admin",
  // },
  // {
  //   path: "/calendar",
  //   name: "Calendar",
  //   icon: "now-ui-icons media-1_album",
  //   component: Calendar,
  //   layout: "/admin",
  // },
  // {
  //   collapse: true,
  //   path: "/pages",
  //   name: "Pages",
  //   state: "openPages",
  //   icon: "now-ui-icons design_image",
  //   views: [
  //     {
  //       path: "/timeline-page",
  //       name: "Timeline Page",
  //       mini: "TP",
  //       component: TimelinePage,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/user-page",
  //       name: "User Profile",
  //       mini: "UP",
  //       component: UserPage,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/rtl-support",
  //       name: "RTL Support",
  //       mini: "RS",
  //       component: RTL,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/register-page",
  //       name: "Register Page",
  //       short: "Register",
  //       mini: "RP",
  //       component: RegisterPage,
  //       layout: "/auth",
  //     },
  //     {
  //       path: "/login-page",
  //       name: "Login Page",
  //       short: "Login",
  //       mini: "LP",
  //       component: LoginPage,
  //       layout: "/auth",
  //     },
  //     {
  //       path: "/pricing-page",
  //       name: "Pricing Page",
  //       short: "Pricing",
  //       mini: "PP",
  //       component: PricingPage,
  //       layout: "/auth",
  //     },
  //     {
  //       path: "/lock-screen-page",
  //       name: "Lock Screen Page",
  //       short: "Lock",
  //       mini: "LSP",
  //       component: LockScreenPage,
  //       layout: "/auth",
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   path: "/components",
  //   name: "Components",
  //   state: "openComponents",
  //   icon: "now-ui-icons education_atom",
  //   views: [
  //     {
  //       path: "/buttons",
  //       name: "Buttons",
  //       mini: "B",
  //       component: Buttons,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/grid-system",
  //       name: "Grid System",
  //       mini: "GS",
  //       component: GridSystem,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/panels",
  //       name: "Panels",
  //       mini: "P",
  //       component: Panels,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/sweet-alert",
  //       name: "Sweet Alert",
  //       mini: "SA",
  //       component: SweetAlert,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/notifications",
  //       name: "Notifications",
  //       mini: "N",
  //       component: Notifications,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/icons",
  //       name: "Icons",
  //       mini: "I",
  //       component: Icons,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/typography",
  //       name: "Typography",
  //       mini: "T",
  //       component: Typography,
  //       layout: "/admin",
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   path: "/forms",
  //   name: "Forms",
  //   state: "openForms",
  //   icon: "now-ui-icons design_bullet-list-67",
  //   views: [
  //     {
  //       path: "/regular-forms",
  //       name: "Regular Forms",
  //       mini: "RF",
  //       component: RegularForms,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/extended-forms",
  //       name: "Extended Forms",
  //       mini: "EF",
  //       component: ExtendedForms,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/validation-forms",
  //       name: "Validation Forms",
  //       mini: "VF",
  //       component: ValidationForms,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/wizard",
  //       name: "Wizard",
  //       mini: "W",
  //       component: Wizard,
  //       layout: "/admin",
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   path: "/tables",
  //   name: "Tables",
  //   state: "openTables",
  //   icon: "now-ui-icons files_single-copy-04",
  //   views: [
  //     {
  //       path: "/regular-tables",
  //       name: "Regular Tables",
  //       mini: "RT",
  //       component: RegularTables,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/extended-tables",
  //       name: "Extended Tables",
  //       mini: "ET",
  //       component: ExtendedTables,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/react-table",
  //       name: "React Table",
  //       mini: "RT",
  //       component: ReactTable,
  //       layout: "/admin",
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   path: "/maps",
  //   name: "Maps",
  //   state: "openMaps",
  //   icon: "now-ui-icons location_pin",
  //   views: [
  //     {
  //       path: "/google-maps",
  //       name: "Google Maps",
  //       mini: "GM",
  //       component: GoogleMaps,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/full-screen-maps",
  //       name: "Full Screen Map",
  //       mini: "FSM",
  //       component: FullScreenMap,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/vector-maps",
  //       name: "Vector Map",
  //       mini: "VM",
  //       component: VectorMap,
  //       layout: "/admin",
  //     },
  //   ],
  // },

];

export default routes;
