
import React from "react";
import { connect } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footer/Footer.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import routes from "routes.js";
import { Spinner } from "reactstrap";

function Auth(props) {
  const [filterColor, setFilterColor] = React.useState("yellow");
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const handleColorClick = (color) => {
    setFilterColor(color);
  };

  // console.log("PROPS.ISAUTHENTICATED")
  // console.log(props.isAuthenticated)

  // if (props.isAuthenticated != false)
  //   return <Redirect to="/admin/dashboard" />

  return (
    <>
      <AuthNavbar {...props} />
      <div className="wrapper wrapper-full-page">
        <div className="full-page section-image" filter-color={filterColor}>
          <Switch>
            {getRoutes(routes)}
            <Redirect from="/auth" to="/auth/login-page" />
          </Switch>
          <Footer fluid />
        </div>
      </div>
      {/* <FixedPlugin bgColor={filterColor} handleColorClick={handleColorClick} /> */}
    </>
  );
}

const mapStateToProps = ({ authReducer }) => ({
  isAuthenticated: authReducer.isAuthenticated
});

export default connect(mapStateToProps, {
})(Auth);
