
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Select from "react-select";
import { Link } from 'react-router-dom'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
// react component used to create sweet alerts
// reactstrap components
import {

    Card,
    Container, Col,
    Row, Modal,
    ModalBody,
    ModalFooter,
    ModalHeader, Button, FormGroup, CardHeader, Form, Input, Label, UncontrolledTooltip

} from "reactstrap";
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
// import { dataTable } from "variables/general";
const dataTable = [
    {
        id: '1',
        site: "lorem",
        manager: "Tiger Nixon",
        employee: "Harry Styles",
        booking_date: "22-03-2021",
        status: "Active",
        amount: '$200'



    },
    {
        id: '2',
        site: "lorem",
        manager: "Tiger Nixon",
        employee: "Harry Styles",
        booking_date: "22-03-2021",
        status: "Pending",
        amount: '$200'


    },

    {
        id: '1',
        site: "lorem",
        manager: "Tiger Nixon",
        employee: "Jim Parsons",
        booking_date: "22-03-2021",
        status: "Completed",
        amount: '$200'


    },



];
var selectOptions = [
    { value: "one", label: "One" },
    { value: "two", label: "Two" },
    { value: "three", label: "Three" },
    { value: "four", label: "Four" },
    { value: "five", label: "Five" },
    { value: "six", label: "Six" },
];
const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
        <div className="dataTables_length " id="datatable-basic_length">
            <label className="d-flex justify-content-start">
                Show{" "}
                {
                    <select
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control"
                        onChange={(e) => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                }{" "}
        entries.
      </label>
        </div>
    ),
});


const { SearchBar } = Search;


const Invoice = () => {
    const [modalClassic, setModalClassic] = React.useState(false);
    const toggleModalClassic = () => {
        setModalClassic(!modalClassic);
    };
    const [multipleSelect, setMultipleSelect] = React.useState(null);
    const [multipleSelect1, setMultipleSelect1] = React.useState(null);
    return (
        <>
            <PanelHeader size="sm" />
            <div className="content">



                <Row>
                    <div className="col">
                        <Card>

                            <Container>

                                <ToolkitProvider
                                    data={dataTable}
                                    keyField="id"
                                    bootstrap4

                                    columns={[
                                        {
                                            dataField: "site",
                                            text: "Site",
                                            sort: true,
                                        },
                                        {
                                            dataField: "manager",
                                            text: "Manager",
                                            sort: true,
                                        },
                                        {
                                            dataField: "employee",
                                            text: "Employee",
                                            sort: true,
                                        },

                                        {
                                            dataField: "booking_date",
                                            text: " Date",
                                            sort: true,
                                        },
                                        {
                                            dataField: "amount",
                                            text: "Amount",
                                            sort: false,
                                        },


                                        {
                                            dataField: 'df2',
                                            isDummyField: true,
                                            text: 'Action',
                                            formatter: (cellContent, row) => {
                                                return (
                                                    <h5 className="d-flex">
                                                        {
                                                            row.status === 'Active' ? (<Button size="sm" color="danger">Not Approved</Button>) : (
                                                                <Button size="sm" color="success"> Approved</Button>

                                                            )
                                                        }


                                                    </h5>
                                                );

                                            }
                                        }


                                    ]}
                                    search
                                >
                                    {(props) => (
                                        <div className="py-4 table-responsive">
                                            <div
                                                id="datatable-basic_filter"
                                                className="dataTables_filter px-4 pb-1 d-flex  align-items-center justify-content-between"
                                            >

                                                <label>
                                                    Search:
                                                <SearchBar
                                                        className="form-control"
                                                        placeholder=""
                                                        {...props.searchProps}
                                                    />
                                                </label>
                                                {/* <Link className="btn btn-info btn-sm" to="/admin/add-new-task">Add New Task</Link> */}

                                            </div>
                                            <BootstrapTable
                                                {...props.baseProps}
                                                bootstrap4={true}
                                                pagination={pagination}
                                                bordered={false}
                                            />
                                        </div>
                                    )}
                                </ToolkitProvider>
                            </Container>
                        </Card>

                    </div>


                </Row>
                <Modal
                    isOpen={modalClassic}
                    toggle={toggleModalClassic}
                    className="text-center"
                >
                    <ModalHeader
                        className="justify-content-center uppercase title"
                        toggle={toggleModalClassic}
                        tag="h4"
                    >
                        Add New Task
                      </ModalHeader>
                    <ModalBody>
                        <Form action="#" method="#">
                            <label>Client</label>
                            <FormGroup>
                                <Select
                                    className="react-select warning"
                                    classNamePrefix="react-select"

                                    closeMenuOnSelect={false}
                                    placeholder="Select Client"
                                    name="multipleSelect"
                                    value={multipleSelect}
                                    options={selectOptions}
                                    onChange={(value) => setMultipleSelect(value)}
                                />
                            </FormGroup>
                            <label>Site</label>
                            <FormGroup>
                                <Select
                                    className="react-select warning"
                                    classNamePrefix="react-select"

                                    closeMenuOnSelect={false}
                                    placeholder="Select Site"
                                    name="multipleSelect"
                                    value={multipleSelect1}
                                    options={selectOptions}
                                    onChange={(value) => setMultipleSelect1(value)}
                                />
                            </FormGroup>

                        </Form>
                    </ModalBody>
                    <div className="text-right">
                        <Button color="primary" onClick={toggleModalClassic}>
                            Next
                        </Button>
                    </div>

                </Modal>
            </div>

        </>
    );
}

export default Invoice;






















