
import React, { useEffect } from "react";
// react plugin used to create charts
import { Line } from "react-chartjs-2";
// react plugin for creating vector maps
import { VectorMap } from "react-jvectormap";

import { Link } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Table,
  Progress,
  Row,
  Col,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";

import {
  dashboardPanelChart,
  dashboardActiveUsersChart,
  dashboardSummerChart,
  dashboardActiveCountriesCard,
} from "variables/charts.js";

import jacket from "assets/img/saint-laurent.jpg";
import shirt from "assets/img/balmain.jpg";
import swim from "assets/img/prada.jpg";

import { table_data } from "variables/general.js";
import { getDashboardData } from "actions/profileActions";
import { connect } from "react-redux";

var mapData = {
  AU: 760,
  BR: 550,
  CA: 120,
  DE: 1300,
  FR: 540,
  GB: 690,
  GE: 200,
  IN: 200,
  RO: 600,
  RU: 300,
  US: 2920,
};

function Dashboard(props) {
  const { getDashboardData, dashboardData } = props
  const createTableData = () => {
    var tableRows = [];
    for (var i = 0; i < table_data.length; i++) {
      tableRows.push(
        <tr key={i}>
          <td>
            <div className="flag">
              <img src={table_data[i].flag} alt="us_flag" />
            </div>
          </td>
          <td>{table_data[i].country}</td>
          <td className="text-right">{table_data[i].count}</td>
          <td className="text-right">{table_data[i].percentage}</td>
        </tr>
      );
    }
    return tableRows;
  };

  useEffect(() => {
    getDashboardData()
  }, [])
  return (
    <>
      <PanelHeader
        size="sm"
        content=""
      />
      <div className="content">
        <Row>
          <Col xs={4} md={4}>
            <Link to="/admin/jobs">
              <Card className="card-stats card-raised">
                <CardBody>
                  <div className="statistics">
                    <div className="info">
                      <div className="icon icon-info">
                        <i className="fas fa-user" />
                      </div>
                      <h3 className="info-title">{dashboardData?.jobs}</h3>
                      <h6 className="stats-title">Total Jobs</h6>
                    </div>
                  </div>

                </CardBody>
              </Card>
            </Link>

          </Col>
          <Col xs={4} md={4}>
            <Link to="/admin/sites">
              <Card className="card-stats card-raised">
                <CardBody>
                  <div className="statistics">
                    <div className="info">
                      <div className="icon icon-info">
                        <i className="fas fa-user" />
                      </div>
                      <h3 className="info-title">
                        {dashboardData?.sites}
                      </h3>
                      <h6 className="stats-title">Total Sites</h6>
                    </div>
                  </div>
                </CardBody>

              </Card>
            </Link>
          </Col>
          {/* <Col xs={4} md={4}>
            <Card className="card-stats card-raised">
              <CardBody>
                <div className="statistics">
                  <div className="info">
                    <div className="icon icon-info">
                      <i className="fas fa-user" />
                    </div>
                    <h3 className="info-title">2</h3>
                    <h6 className="stats-title">BOOKED SHIFTS IN
                      NEXT 14 DAYS</h6>
                  </div>
                </div>
              </CardBody>

            </Card>
          </Col> */}
          {/* <Col xs={4} md={4}>
            <Card className="card-stats card-raised">
              <CardBody>
                <div className="statistics">
                  <div className="info">
                    <div className="icon icon-danger">
                      <i className="now-ui-icons objects_support-17" />
                    </div>
                    <h3 className="info-title">353</h3>
                    <h6 className="stats-title">Total Jobs</h6>
                  </div>
                </div>
              </CardBody>

            </Card>
          </Col>
          <Col xs={4} md={4}>
            <Card className="card-stats card-raised">
              <CardBody>
                <div className="statistics">
                  <div className="info">
                    <div className="icon icon-danger">
                      <i className="now-ui-icons objects_support-17" />
                    </div>
                    <h3 className="info-title">353</h3>
                    <h6 className="stats-title">Active Jobs</h6>
                  </div>
                </div>
              </CardBody>

            </Card>
          </Col>
          <Col xs={4} md={4}>
            <Card className="card-stats card-raised">
              <CardBody>
                <div className="statistics">
                  <div className="info">
                    <div className="icon icon-danger">
                      <i className="now-ui-icons objects_support-17" />
                    </div>
                    <h3 className="info-title">353</h3>
                    <h6 className="stats-title">Past Jobs</h6>
                  </div>
                </div>
              </CardBody>

            </Card>
          </Col>
          <Col xs={4} md={4}>
            <Card className="card-stats card-raised">
              <CardBody>
                <div className="statistics">
                  <div className="info">
                    <div className="icon icon-danger">
                      <i className="now-ui-icons objects_support-17" />
                    </div>
                    <h3 className="info-title">353</h3>
                    <h6 className="stats-title">Total Sites</h6>
                  </div>
                </div>
              </CardBody>

            </Card>
          </Col>
          <Col xs={4} md={4}>
            <Card className="card-stats card-raised">
              <CardBody>
                <div className="statistics">
                  <div className="info">
                    <div className="icon icon-danger">
                      <i className="now-ui-icons objects_support-17" />
                    </div>
                    <h3 className="info-title">353</h3>
                    <h6 className="stats-title">Total Stocks</h6>
                  </div>
                </div>
              </CardBody>

            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4}>
            <Card className="card-chart">
              <CardHeader>
                <h5 className="card-category">Active Users</h5>
                {/* <CardTitle tag="h4">34,252</CardTitle> *

              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <Line
                    data={dashboardActiveUsersChart.data}
                    options={dashboardActiveUsersChart.options}
                  />
                </div>

              </CardBody>
              <CardFooter>
                <div className="stats">
                  <i className="now-ui-icons arrows-1_refresh-69" />
                  Just Updated
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col xs={12} md={4}>
            <Card className="card-chart">
              <CardHeader>
                <h5 className="card-category">Annual Earnings</h5>
                {/* <CardTitle tag="h4">55,300</CardTitle> *

              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <Line
                    data={dashboardSummerChart.data}
                    options={dashboardSummerChart.options}
                  />
                </div>

              </CardBody>
              <CardFooter>
                <div className="stats">
                  <i className="now-ui-icons arrows-1_refresh-69" />
                  Just Updated
                </div>
              </CardFooter>
            </Card>
          </Col> */}

        </Row>

      </div>
    </>
  );
}
const mapStateToProps = state => ({

  user: state.authReducer.user,
  dashboardData: state.profileReducer.dashboardData,
})

export default connect(mapStateToProps, { getDashboardData })(Dashboard);  
