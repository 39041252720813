
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect, HashRouter } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/now-ui-dashboard.scss?v=1.5.0";
import "assets/css/demo.css";
import "assets/css/custom.css";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
// Redux Store

import { Provider } from 'react-redux'
import store from './store'
import setAuthToken from "utils/setAuthToken";
import { loadUser } from "actions/authActions";
// import PrivateRoute from "routing/PrivateRoute";
import { Spinner } from "reactstrap";

if (localStorage.token) {
    setAuthToken(localStorage.token)
}



const App = (props) => {
    const {
    } = props

    useEffect(() => {
        store.dispatch(loadUser());
    })

    return (
        <>
            <Provider store={store}>
                <HashRouter >
                    <Switch>

                        <Route
                            path="/admin"
                            render={(props) => {
                                return <AdminLayout {...props} />;
                            }}
                        />
                        <Route
                            path="/auth"
                            render={(props) => {
                                return <AuthLayout {...props} />;
                            }}
                        />
                        <Redirect to="/admin/dashboard" />
                    </Switch>
                </HashRouter>
            </Provider>
        </>
    )

}

export default App

