import {
    USER_LOADED,
    AUTH_ERROR,
    LOGIN_FAIL,
    LOGIN_SUCCESS,
    LOGOUT,
    CHANGE_LOADING_AUTH,
    CHANGE_IS_AUTHENTICATED,
    CHANGE_ADD_NOTE,
    SELECTED_JOB_DATA, CHANGE_GET_ALL_NOTES
} from "../actions/types";

const initialState = {
    allNotes: [],
    addNote: null,
    selectedJobData: null
}

export default function (state = initialState, { type, payload }) {
    switch (type) {
        case SELECTED_JOB_DATA:
            return {
                ...state,
                selectedJobData: payload
            }
        case CHANGE_ADD_NOTE:
            return {
                ...state,
                addNote: payload
            }
        case CHANGE_GET_ALL_NOTES:
            return {
                ...state,
                allNotes: payload
            }
        default:
            return state;
    }
}