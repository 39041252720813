
import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Select from "react-select";
import { Link, useHistory } from 'react-router-dom'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { connect } from 'react-redux';
import {
    getAllSites,
    deleteSite,
    updateSite,
    addNewSite,
    getUserProfile,
    getAllClients,
    removeSweetAlert
} from "actions/profileActions";
import SweetAlert from "react-bootstrap-sweetalert";

// react component used to create sweet alerts
// reactstrap components
import {

    Card,
    Container, Col,
    Row, Modal,
    ModalBody,
    ModalFooter,
    ModalHeader, Button, FormGroup, CardHeader, Form, Input, Label, UncontrolledTooltip

} from "reactstrap";
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";

const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
        <div className="dataTables_length " id="datatable-basic_length">
            <label className="d-flex justify-content-start">
                Show{" "}
                {
                    <select
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control"
                        onChange={(e) => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                }{" "}
                entries.
            </label>
        </div>
    ),
});


var positions;

const RegularMap = () => {
    const mapRef = React.useRef(null);
    React.useEffect(() => {
        let google = window.google;
        let map = mapRef.current;
        let lat = "40.748817";
        let lng = "-73.985428";
        const myLatlng = new google.maps.LatLng(lat, lng);
        const mapOptions = {
            zoom: 13,
            center: myLatlng,
            scrollwheel: false,
        };

        map = new google.maps.Map(map, mapOptions);

        const marker = new google.maps.Marker({
            position: myLatlng,
            map: map,
            animation: google.maps.Animation.DROP,
            title: "Set Location",
        });


        // const contentString =
        //     '';

        let infoWindow = new google.maps.InfoWindow({
            content: "Select a Location",
            position: myLatlng,
        });
        infoWindow.open(map, marker);

        map.addListener("click", (mapsMouseEvent) => {
            console.log('clicked')
            console.log(mapsMouseEvent.latLng.toJSON(), null, 2)
            positions = mapsMouseEvent.latLng.toJSON()

        });

    }, []);
    return (
        <>
            <div style={{ height: `280px` }} ref={mapRef} />
        </>
    );
};

const { SearchBar } = Search;


const ManageSite = ({ getAllSites, allSites, allClients, deleteSite, updateSite, getUserProfile, user, getAllClients, removeSweetAlert, isLoading, deletedSite, updatedSite, addedSite, sweetAlert }) => {
    const [mapLocations, setMapLocations] = useState(positions)

    useEffect(() => {
        setMapLocations(positions)

    }, [positions])

    // Sweet Alert

    const [alert, setAlert] = React.useState(null);
    React.useEffect(() => {
        return function cleanup() {
            var id = window.setTimeout(null, 0);
            while (id--) {
                window.clearTimeout(id);
            }
        };
    }, []);

    useEffect(() => {
        if (sweetAlert) {
            successAlert()


        } else {
            hideAlert()
        }
    }, [sweetAlert])

    const successAlert = () => {
        setAlert(
            <SweetAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="Success!"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnBsStyle="info"
            >
                {deletedSite ? deletedSite : updatedSite ? updatedSite : addedSite ? addedSite : null}
            </SweetAlert>
        );
    }
    const hideAlert = () => {
        setAlert(null);
        removeSweetAlert()
    };



    const history = useHistory()
    const [modalClassic, setModalClassic] = React.useState(false);
    const toggleModalClassic = () => {
        setModalClassic(!modalClassic);
    };
    const [modalClassic1, setModalClassic1] = React.useState(false);
    const toggleModalClassic1 = () => {
        setModalClassic1(!modalClassic1);
    };
    const [modalClassic2, setModalClassic2] = React.useState(false);
    const toggleModalClassic2 = () => {
        setModalClassic2(!modalClassic2);
    };

    const [sites, setSites] = useState([]);


    // Set Table

    useEffect(() => {
        getAllSites();


    }, [])

    useEffect(() => {
        if (allSites.length !== 0) {
            setSites(allSites.sites.reverse())


        } else {
            return null
        }
    })

    // View Site

    const [siteProfile, setSiteProfile] = useState([])
    const setModal = (row) => {
        toggleModalClassic()
        setSiteProfile(row)


    }

    // Update Site

    const setUpdateSite = (row) => {
        toggleModalClassic2()
        setSiteProfile(row)



    }


    useEffect(() => {
        getAllClients()
    }, [])


    //  ---------------------------------------------- Form Data ---------------------------------

    var [areas, setAreas] = useState([])

    const [buildingareas, setbuildingareas] = useState([

    ])
    const addNewArea = () => {
        let newArr = [...buildingareas]
        const newObj = {
            name: ''
        }
        newArr.push(newObj)
        setbuildingareas(newArr)
    }
    const changeAreaName = (index) => e => {
        let newArr = [...buildingareas]
        newArr[index]['name'] = e.target.value;
        setbuildingareas(newArr)
    }







    var [accesses, setAccesses] = useState([

    ])
    var [securityAccess, setSecurityAccess] = useState([])
    const addNewAccess = () => {
        let newArr = [...accesses]
        const newObj = {
            name: ''
        }
        newArr.push(newObj)
        setAccesses(newArr)
    }
    const changeAccessName = (index) => e => {
        let newArr = [...accesses]
        newArr[index]['name'] = e.target.value;
        setAccesses(newArr)
    }

    const [formData, setFormData] = useState({

        client_id: '',
        company_id: '1',
        site_name: '',
        emergency_contact_no: '',
        emergency_phone_no: '',
        out_of_hours: '',
        alarm_call: '',
        lat: mapLocations ? mapLocations.lat : '',
        lan: mapLocations ? mapLocations.lng : '',
        location: '',
        town: '',
        post_code: "",
        address: "",
        email: "",
        client_contact: "",
        file: '',
        buildings: accesses,
    })



    var { client_id, company_id, site_name, emergency_contact_no, out_of_hours, emergency_phone_no, alarm_call, lat, lan, location, town, post_code, address, email, client_contact, file, buildings } = formData;
    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })
    const onChange1 = e => setFormData({ ...formData, [e.target.name]: e.target.files[0] })


    useEffect(() => {
        if (client_id === '' || client_id === null || client_id === undefined) {
            return null
        } else {
            var formdata = new FormData();
            formdata.append("id", client_id);
            getUserProfile(formdata)
        }
    }, [client_id])



    const onSubmit = e => {
        e.preventDefault();

        if (client_id === '') {
            client_id = siteProfile.client_id
        }
        if (site_name === '') {
            site_name = siteProfile.site_name
        }
        if (emergency_contact_no === '') {
            emergency_contact_no = siteProfile.emergency_contact_no
        }
        if (emergency_phone_no === '') {
            emergency_phone_no = siteProfile.emergency_phone_no
        }
        if (out_of_hours === '') {
            out_of_hours = siteProfile.out_of_hours
        }
        if (alarm_call === '') {
            alarm_call = siteProfile.alarm_call
        }
        if (lat === '') {
            lat = siteProfile.lat
        }
        if (lan === '') {
            lan = siteProfile.lan
        }
        if (location === '') {
            location = siteProfile.location
        }
        if (file === '') {
            file = siteProfile.file
        }


        buildingareas.map(buildingarea => (areas.push(buildingarea.name)))
        accesses.map(access => (securityAccess.push(access.name)))

        // if (areas.length <= 0) {
        //     areas = siteProfile.areas
        // }
        // if (securityAccess.length <= 0) {
        //     securityAccess = siteProfile.buildings
        // }




        var raw = {

            "site_id": JSON.stringify(siteProfile.id),
            "company_id": "1",
            "client_id": client_id,
            "site_name": site_name,
            "emergency_contact_no": emergency_contact_no,
            "emergency_phone_no": emergency_phone_no,
            "out_of_hours": out_of_hours,
            "alarm_call": alarm_call,
            "lat": lat,
            "lan": lan,
            "location": location,
            "town": !user ? (siteProfile.client.town) : (user.town),
            "post_code": !user ? (siteProfile.client.post_code) : (user.post_code),
            "address": !user ? (siteProfile.client.address) : (user.address),
            "email": !user ? (siteProfile.client.email) : (user.email),
            "client_contact": !user ? (siteProfile.client.mobile_no) : (user.mobile_no),
            "file": file,
            "areas": areas,
            "buildings": securityAccess


        }
        // console.log(raw)
        updateSite(raw, history)



        // var formData = new FormData();
        // formData.append("site_id", siteProfile.id);
        // formData.append("client_id", client_id);
        // formData.append("company_id", company_id);
        // formData.append("site_name", site_name);
        // formData.append("emergency_contact_no", emergency_contact_no);
        // formData.append("emergency_phone_no", emergency_phone_no);
        // formData.append("out_of_hours", out_of_hours);
        // formData.append("alarm_call", alarm_call);
        // formData.append("lat", lat);
        // formData.append("lan", lan);
        // formData.append("location", location);

        // setFormData({
        //     client_id: '',
        //     company_id: '1',
        //     site_name: '',
        //     emergency_contact_no: '',
        //     emergency_phone_no: '',
        //     out_of_hours: '',
        //     alarm_call: '',
        //     lat: '-25.60102173347362',
        //     lan: '131.77642008686067',
        //     location: ''

        // })


    }



    function iconFormatter(cell, row) {

        if (row.client) {
            return (
                <span>  {row.client.fname + ' ' + row.client.lname} </span>
            );
        } else {
            return null
        }


    }


    return (
        <>
            <PanelHeader size="sm" />
            <div className="content">
                {alert}


                <Row>
                    <div className="col">
                        <Card>

                            <Container>

                                <ToolkitProvider
                                    data={sites}
                                    keyField="id"
                                    bootstrap4

                                    columns={[
                                        {
                                            dataField: "site_name",
                                            text: "Site",
                                            sort: true,
                                        },
                                        {
                                            dataField: "client.fname",
                                            text: "Represenative",
                                            sort: true,
                                            formatter: iconFormatter


                                        },
                                        // {
                                        //     dataField: "company_id",
                                        //     text: "Company",
                                        //     sort: true,
                                        // },
                                        {
                                            dataField: "emergency_contact_no",
                                            text: "Contact",
                                            sort: true,
                                        },
                                        {
                                            dataField: "created_at",
                                            text: "Date Created",
                                            sort: true,
                                            formatter: (cellContent, row) => {
                                                return (
                                                    <span className="">
                                                        {new Date(cellContent).toLocaleString()}

                                                    </span>
                                                );

                                            }
                                        },



                                        {
                                            dataField: 'df2',
                                            isDummyField: true,
                                            text: 'Action',
                                            formatter: (cellContent, row) => {
                                                return (
                                                    <h5 className="">
                                                        <span className="btn btn-info btn-sm m-1" onClick={() => setModal(row)} > <i className="fas fa-eye"></i></span>
                                                        {/* <span className="btn btn-info btn-sm m-1" onClick={() => setUpdateSite(row)} > <i className="fas fa-edit"></i></span>

                                                        {
                                                            isLoading ? (
                                                                <div className="spinner-border text-primary" role="status">
                                                                    <span className="sr-only">Loading...</span>
                                                                </div>
                                                            ) : (
                                                                <span className="btn btn-danger btn-sm m-1" onClick={() => deleteSite(row.id, history)} > <i className="fas fa-times"></i></span>
                                                            )
                                                        } */}

                                                    </h5>
                                                );

                                            }
                                        }


                                    ]}
                                    search
                                >
                                    {(props) => (
                                        <div className="py-4 ">
                                            <div
                                                id="datatable-basic_filter"
                                                className="dataTables_filter px-4 pb-1 d-flex  align-items-center justify-content-between"
                                            >

                                                <label>
                                                    Search:
                                                    <SearchBar
                                                        className="form-control"
                                                        placeholder=""
                                                        {...props.searchProps}
                                                    />
                                                </label>
                                                {/* <Link className="btn btn-info btn-sm" to="/admin/create-site">Add New Site</Link> */}

                                            </div>
                                            <BootstrapTable
                                                {...props.baseProps}
                                                bootstrap4={true}
                                                pagination={pagination}
                                                bordered={false}
                                            />
                                        </div>
                                    )}
                                </ToolkitProvider>
                            </Container>
                        </Card>

                    </div>


                </Row>
                {/* View Profile Modal */}
                <Modal
                    isOpen={modalClassic}
                    toggle={toggleModalClassic}
                    className="text-center"
                    size="lg"
                >
                    <ModalHeader
                        className="justify-content-center uppercase title text-left"
                        toggle={toggleModalClassic}
                        tag="h6"
                    >
                        {siteProfile.site_name}
                    </ModalHeader>
                    <ModalBody>
                        <table className="table table-bordered">
                            <tbody>
                                <tr className="border">
                                    <td className="border-0 font-weight-bold">Client</td>
                                    <td className="border-0">{`${siteProfile.client?.fname} ${siteProfile.client?.lname}`}</td>
                                    <td className="border-0 font-weight-bold">Emergency Contact Name</td>
                                    <td className="border-0">{siteProfile.emergency_contact_no}</td>

                                </tr>
                                <tr className="border">
                                    <td className="border-0 font-weight-bold">Out of Hours</td>
                                    <td className="border-0">{siteProfile.out_of_hours}</td>
                                    <td className="border-0 font-weight-bold">Alarm Call</td>
                                    <td className="border-0">{siteProfile.alarm_call}</td>
                                </tr>
                                <tr className="border">
                                    <td className="border-0 font-weight-bold">Location</td>
                                    <td className="border-0">{siteProfile.location}</td>
                                    <td className="border-0 font-weight-bold">Status</td>
                                    <td className="border-0">{siteProfile.status === '0' ? 'InActive' : 'Active'}</td>
                                </tr>
                                <tr className="border">
                                    <td className="border-0 font-weight-bold">Address</td>
                                    <td className="border-0">{siteProfile.address}</td>
                                    <td className="border-0 font-weight-bold">Post Code</td>
                                    <td className="border-0">{siteProfile.post_code}</td>
                                </tr>
                                <tr className="border">
                                    <td className="border-0 font-weight-bold">Town</td>
                                    <td className="border-0">{siteProfile.town}</td>
                                    <td className="border-0 font-weight-bold">Emergency Phone #</td>
                                    <td className="border-0">{siteProfile.emergency_phone_no}</td>
                                </tr>
                                <tr className="border">
                                    <td className="border-0 font-weight-bold">Picture</td>
                                    <td className="border-0">
                                        <img
                                            src={`https://api.eagleiq.online/${siteProfile?.attachment}`}
                                            alt="Profile"
                                            style={{
                                                width: '200px'
                                            }}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </ModalBody>
                    {/* <div className="text-right">
                        <Button color="primary" onClick={toggleModalClassic}>
                            Next
                        </Button>
                    </div> */}

                </Modal>


            </div>

        </>
    );
}

const mapStateToProps = state => ({
    allSites: state.profileReducer.allSites,
    user: state.profileReducer.userProfile.user,
    allClients: state.profileReducer.allClients,
    sweetAlert: state.profileReducer.sweetAlert,
    isLoading: state.profileReducer.isLoading,
    deletedSite: state.profileReducer.deletedSite.message,
    addedSite: state.profileReducer.newSite.message,
    updatedSite: state.profileReducer.updatedSite.message
})

export default connect(mapStateToProps, { getAllSites, addNewSite, updateSite, deleteSite, getUserProfile, getAllClients, removeSweetAlert })(ManageSite);






















