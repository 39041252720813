
import {
    ChangeSelectedJobData,
    getAllNotes
} from "actions/notesActions";
import {
    addNewSite,
    deleteJob,
    deleteSite,
    getAllClients,
    getAllJobs,
    getAllSites,
    getUserProfile,
    removeSweetAlert,
    updateSite
} from "actions/profileActions";
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import htmlToPdfmake from 'html-to-pdfmake';
import jsPDF from 'jspdf';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import React, {
    useEffect,
    useState
} from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import BootstrapTable from "react-bootstrap-table-next";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
    Search
} from "react-bootstrap-table2-toolkit";
import ReactHtmlParser from 'react-html-parser';
import {
    connect
} from 'react-redux';
import {
    useHistory
} from 'react-router-dom';
import {
    Button,
    Card,
    Col,
    Form,
    FormGroup,
    Input,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    Table,
    UncontrolledTooltip
} from "reactstrap";

const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
        <div className="dataTables_length " id="datatable-basic_length">
            <label className="d-flex justify-content-start">
                Show{" "}
                {
                    <select
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control"
                        onChange={(e) => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                }{" "}
                entries.
            </label>
        </div>
    ),
});


const RegularMap = () => {
    const mapRef = React.useRef(null);
    React.useEffect(() => {
        let google = window.google;
        let map = mapRef.current;
        let lat = "40.748817";
        let lng = "-73.985428";
        const myLatlng = new google.maps.LatLng(lat, lng);
        const mapOptions = {
            zoom: 13,
            center: myLatlng,
            scrollwheel: false,
        };

        map = new google.maps.Map(map, mapOptions);

        const marker = new google.maps.Marker({
            position: myLatlng,
            map: map,
            animation: google.maps.Animation.DROP,
            title: "Set Location",
        });


        const contentString =
            '<div class="info-window-content"><h2>Location</h2>' +
            "<p>Set Location</p></div>";

        const infowindow = new google.maps.InfoWindow({
            content: contentString,
        });

        google.maps.event.addListener(marker, "click", function () {
            infowindow.open(map, marker);



        });
    }, []);
    return (
        <>
            <div style={{ height: `280px` }} ref={mapRef} />
        </>
    );
};

const { SearchBar } = Search;


const ManageJobs = (props) => {

    const {
        getAllSites,
        allSites,
        allClients,
        deleteSite,
        updateSite,
        getUserProfile,
        user,
        getAllClients,
        getAllJobs,
        allJobs,
        deleteJob,
        isLoading,
        removeSweetAlert,
        sweetAlert,
        deletedMsg,
        newJobmsg,
        ChangeSelectedJobData,
        getLatestJob,
        getAllNotes
    } = props

    // PDF DOWNLOADER

    const printDocument = () => {
        //const input = document.getElementById('divToPrint');

        const doc = new jsPDF();

        //get table html
        const pdfTable = document.getElementById('divToPrint');
        //html to pdf format
        var html = htmlToPdfmake(pdfTable.innerHTML);

        const documentDefinition = { content: html };
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        pdfMake.createPdf(documentDefinition).open();

    }

    // Loading Spinner
    const [loadingSpinner, setLoadingSpinner] = useState(false)

    useEffect(() => {

        setLoadingSpinner(isLoading)

    }, [isLoading])


    // Sweet Alert

    const [alert, setAlert] = React.useState(null);
    React.useEffect(() => {
        return function cleanup() {
            var id = window.setTimeout(null, 0);
            while (id--) {
                window.clearTimeout(id);
            }
        };
    }, []);

    useEffect(() => {
        if (sweetAlert) {
            successAlert()


        } else {
            hideAlert()
        }
    }, [sweetAlert])

    const successAlert = () => {
        setAlert(
            <SweetAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="Success!"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnBsStyle="info"
            >
                {deletedMsg ? deletedMsg : newJobmsg ? newJobmsg : null}
            </SweetAlert>
        );
    }
    const hideAlert = () => {
        setAlert(null);
        removeSweetAlert()
    };


    // PDF DOWNLOADER END


    const history = useHistory()
    const [modalClassic, setModalClassic] = React.useState(false);
    const toggleModalClassic = () => {
        setModalClassic(!modalClassic);
    };
    const [modalClassic1, setModalClassic1] = React.useState(false);
    const toggleModalClassic1 = () => {
        setModalClassic1(!modalClassic1);
    };
    const [modalClassic2, setModalClassic2] = React.useState(false);
    const toggleModalClassic2 = () => {
        setModalClassic2(!modalClassic2);
    };

    const [jobs, setJobs] = useState([]);


    // Set Table

    useEffect(() => {
        getAllJobs();
    }, [])

    useEffect(() => {
        if (allJobs.length !== 0) {
            setJobs(allJobs.jobs.reverse())


        } else {
            return null
        }
    })

    // View Site

    const [siteProfile, setSiteProfile] = useState([])
    const setModal = (row) => {
        toggleModalClassic()
        setSiteProfile(row)


    }

    // Update Site

    const setUpdateSite = (row) => {
        toggleModalClassic2()
        setSiteProfile(row)



    }

    const [formData, setFormData] = useState({

        client_id: '',
        company_id: '1',
        site_name: '',
        emergency_contact_no: '',
        emergency_phone_no: '',
        out_of_hours: '',
        alarm_call: '',
        lat: '-25.60102173347362',
        lan: '131.77642008686067',
        location: ''
    })


    var { client_id, company_id, site_name, emergency_contact_no, out_of_hours, emergency_phone_no, alarm_call, lat, lan, location } = formData;
    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

    useEffect(() => {
        getAllClients()
    }, [])

    useEffect(() => {
        if (client_id === '' || client_id === null || client_id === undefined) {
            return null
        } else {
            var formdata = new FormData();
            formdata.append("id", client_id);
            getUserProfile(formdata)
        }
    }, [client_id])


    const onSubmit = e => {
        e.preventDefault();

        if (site_name === '') {
            site_name = siteProfile?.site_name
        }
        if (emergency_contact_no === '') {
            emergency_contact_no = siteProfile?.emergency_contact_no
        }
        if (emergency_phone_no === '') {
            emergency_phone_no = siteProfile?.emergency_phone_no
        }
        if (out_of_hours === '') {
            out_of_hours = siteProfile?.out_of_hours
        }
        if (alarm_call === '') {
            alarm_call = siteProfile?.alarm_call
        }
        if (lat === '') {
            lat = siteProfile?.lat
        }
        if (lan === '') {
            lan = siteProfile?.lan
        }
        if (location === '') {
            location = siteProfile?.location
        }


        var formData = new FormData();
        formData.append("site_id", siteProfile?.id);
        formData.append("client_id", client_id);
        formData.append("company_id", company_id);
        formData.append("site_name", site_name);
        formData.append("emergency_contact_no", emergency_contact_no);
        formData.append("emergency_phone_no", emergency_phone_no);
        formData.append("out_of_hours", out_of_hours);
        formData.append("alarm_call", alarm_call);
        formData.append("lat", lat);
        formData.append("lan", lan);
        formData.append("location", location);
        updateSite(formData, history)

        setFormData({
            client_id: '',
            company_id: '1',
            site_name: '',
            emergency_contact_no: '',
            emergency_phone_no: '',
            out_of_hours: '',
            alarm_call: '',
            lat: '-25.60102173347362',
            lan: '131.77642008686067',
            location: ''

        })


    }



    function iconFormatter(cell, row) {


        if (row.client) {
            return (
                <span>  {row?.client?.fname + ' ' + row?.client?.lname} </span>
            );
        } else {
            return null
        }

    }

    function managerFormatter(cell, row) {


        if (row?.manager) {
            return (
                <span>  {row?.manager?.fname + ' ' + row?.manager?.lname} </span>
            );
        } else {
            return null
        }

    }


    // Set View Modal
    const [jobProfile, setJobProfile] = useState('')
    const setViewModal = (row) => {
        toggleModalClassic();
        setJobProfile(row)

    }


    return (
        <>
            <PanelHeader size="sm" />
            <div className="content">
                {alert}


                <Row>
                    <div className="col">
                        <Card>


                            <ToolkitProvider
                                data={jobs}
                                keyField="id"
                                bootstrap4

                                columns={[
                                    {
                                        dataField: "code",
                                        text: "Contract ID",
                                        sort: true,
                                    },

                                    {
                                        dataField: "title",
                                        text: "Division",
                                        sort: true,
                                    },
                                    {
                                        dataField: "client.fname",
                                        text: "Representative",
                                        sort: true,
                                        formatter: iconFormatter


                                    },
                                    {
                                        dataField: "site.site_name",
                                        text: "Site",
                                        sort: true,
                                    },
                                    // {
                                    //     dataField: "summary",
                                    //     text: "Summary",
                                    //     sort: true,
                                    // },
                                    {
                                        dataField: "created_at",
                                        text: "Date Created",
                                        sort: true,
                                        formatter: (cellContent, row) => {
                                            return (
                                                <span className="">
                                                    {new Date(cellContent)?.toLocaleString()}

                                                </span>
                                            );

                                        }
                                    },
                                    {
                                        dataField: "manager.fname",
                                        text: "Manager",
                                        sort: true,
                                        formatter: managerFormatter

                                    },



                                    {
                                        dataField: 'df2',
                                        isDummyField: true,
                                        text: 'Action',
                                        formatter: (cellContent, row) => {
                                            return (
                                                <h5 className="d-flex flex-wrap">
                                                    <span className="btn btn-info btn-sm m-1" onClick={() => setViewModal(row)} > <i className="fas fa-eye"></i></span>
                                                    {/* {
                                                        isLoading ? (
                                                            <div class="spinner-border text-info" style="width: 3rem; height: 3rem;" role="status">
                                                                <span class="sr-only">Loading...</span>
                                                            </div>

                                                        ) : (
                                                            <span className="btn btn-danger btn-sm m-1" onClick={() => deleteJob(row?.id, history)} > <i className="fas fa-times"></i></span>
                                                        )
                                                    } */}

                                                    <button id="tooltip590841497" onClick={() => { ChangeSelectedJobData(row); getAllNotes(row?.id); console.log("🚀 ~ file: ManageJobs.js ~ line 505 ~ ManageJobs ~ row", row); localStorage.setItem("jobId", row?.id); history.push("/admin/notes") }} className="btn btn-success btn-sm m-1" > <i className="fas fa-plus-circle"></i></button>

                                                    <UncontrolledTooltip
                                                        delay={0}
                                                        target="tooltip590841497"
                                                    >Add Notes</UncontrolledTooltip>
                                                </h5>
                                            );

                                        }
                                    }


                                ]}
                                search
                            >
                                {(props) => (
                                    <div className="py-4 ">
                                        <div
                                            id="datatable-basic_filter"
                                            className="dataTables_filter px-4 pb-1 d-flex  align-items-center justify-content-between"
                                        >

                                            <label>
                                                Search:
                                                <SearchBar
                                                    className="form-control"
                                                    placeholder=""
                                                    {...props.searchProps}
                                                />
                                            </label>
                                            {/* <Link className="btn btn-info btn-sm" to="/admin/add-job" onClick={getLatestJob}>Add New Job</Link> */}

                                        </div>
                                        <BootstrapTable
                                            {...props.baseProps}
                                            bootstrap4={true}
                                            pagination={pagination}
                                            bordered={false}
                                        />
                                    </div>
                                )}
                            </ToolkitProvider>
                        </Card>

                    </div>


                </Row>
                {/* View Profile Modal */}
                <Modal
                    isOpen={modalClassic}
                    toggle={toggleModalClassic}
                    className="text-center"
                    size="xl"
                >
                    <ModalHeader
                        className="justify-content-start uppercase title text-left"
                        toggle={toggleModalClassic}
                        tag="h6"
                    >
                        Job Details
                    </ModalHeader>
                    <ModalBody >
                        {/* {
                            console.log(jobProfile)
                        } */}



                        {
                            modalClassic ? (
                                <div id="divToPrint" >
                                    <h5 className="text-uppercase">   {jobProfile?.title} </h5>
                                    {/* <table className="table table-bordered" >


                                        <tbody>
                                            {console.log(jobProfile)}
                                            <tr className="border">
                                                <td className="border-0 font-weight-bold">Areas</td>
                                                <td className="border-0">{
                                                    jobProfile.areas.map(area => (
                                                        <span>{area.title},</span>
                                                    ))}


                                                </td>

                                                <td className="border-0 font-weight-bold">Client</td>
                                                <td className="border-0">{jobProfile.client !== null ? (jobProfile.client.fname + ' ' + jobProfile.client.lname) : 'No Clients Found'}</td>

                                            </tr>
                                        
                                            <tr className="border">
                                                <td className="border-0 font-weight-bold">Employees</td>
                                                <td className="border-0">
                                                    {

                                                        jobProfile.employees.map(employee => (
                                                            <span>{employee.employee.fname + ' ' + employee.employee.lname},</span>
                                                        ))
                                                    }


                                                </td>
                                                <td className="border-0 font-weight-bold">Status</td>
                                                <td className="border-0">{jobProfile.status === '0' ? 'InActive' : 'Active'}</td>

                                            </tr>
                                            <tr className="border">
                                                <td className="border-0 font-weight-bold">Manager</td>
                                                <td className="border-0">{jobProfile.manager.fname}</td>
                                                <td className="border-0 font-weight-bold">Start Date</td>
                                                <td className="border-0">{jobProfile.start_date}</td>

                                            </tr>
                                            <tr className="border">
                                                <td className="border-0 font-weight-bold">Summary</td>
                                                <td className="border-0">{jobProfile.summary}</td>
                                                <td className="border-0 font-weight-bold">Last Update Date</td>
                                                <td className="border-0">{jobProfile.updated_at}</td>

                                            </tr>







                                        </tbody>
                                    </table> */}


                                    <Table>

                                        <thead>
                                            <tr>
                                                {/* <th>Item</th> */}
                                                <th className="font-weight-normal">Area</th>
                                                <td className="font-weight-bold">Task</td>
                                                <td className="font-weight-bold">Frequency</td>
                                                <td className="font-weight-bold">Daily</td>
                                                <td className="font-weight-bold">Mon</td>
                                                <td className="font-weight-bold">Tue</td>
                                                <td className="font-weight-bold">Wed</td>
                                                <td className="font-weight-bold">Thu</td>
                                                <td className="font-weight-bold">Fri</td>
                                                <td className="font-weight-bold">Weekend</td>
                                                <td className="font-weight-bold">Monthly</td>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {
                                                jobProfile.areas.map((area, index) => (
                                                    <>
                                                        {
                                                            area.tasks.filter(task => jobProfile.id === task.job_id).map((task, index) => (
                                                                <tr >
                                                                    <td className="font-weight-normal border">{area?.get_area?.name}</td>
                                                                    <td className="border">{ReactHtmlParser(task?.task)}</td>
                                                                    <td className="text-capitalize border">{task?.frequency}</td>
                                                                    <td className="border">
                                                                        {task?.frequency === 'daily' ? (<i className="fas fa-check"></i>) : task?.frequency === 'fortnightly' ? (task?.day_of) : ''}
                                                                    </td>
                                                                    <td className="border">{task?.frequency === 'weekly' ? (task?.day?.includes('Monday') ? (<i className="fas fa-check"></i>) : '') : ''}</td>
                                                                    <td className="border">{task?.frequency === 'weekly' ? (task?.day?.includes('Tuesday') ? (<i className="fas fa-check"></i>) : '') : ''}</td>
                                                                    <td className="border">{task?.frequency === 'weekly' ? (task?.day?.includes('Wednesday') ? (<i className="fas fa-check"></i>) : '') : ''}</td>
                                                                    <td className="border">{task?.frequency === 'weekly' ? (task?.day?.includes('Thursday') ? (<i className="fas fa-check"></i>) : '') : ''}</td>
                                                                    <td className="border">{task?.frequency === 'weekly' ? (task?.day?.includes('Friday') ? (<i className="fas fa-check"></i>) : '') : ''}</td>
                                                                    <td className="border">{task?.frequency === 'weekend' ? (<i className="fas fa-check"></i>) : ''}</td>
                                                                    <td className="border">{task?.frequency === 'monthly' ? (<span>{task.day} of every month</span>) : ''}</td>

                                                                </tr>

                                                            ))
                                                        }
                                                    </>


                                                ))
                                            }


                                        </tbody>
                                    </Table>


                                    {/* <table className="table table-bordered">
                                        <thead className="bg-light">
                                            <tr>
                                                <th>Area</th>
                                                <th>Day</th>
                                                <th>Task</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                jobProfile.areas.map(area => (
                                                    area.tasks.map(task => (
                                                        <tr>
                                                            <td>{area.area.title}</td>
                                                            <td >{task.day}</td>
                                                            <td className="text-left"> {ReactHtmlParser(task.task)}</td>
                                                        </tr>
                                                    ))
                                                ))
                                            } 

                                        </tbody>
                                    </table> */}

                                    {/* <Table>

                                        <thead>
                                            <tr>
                                                <th className="font-weight-normal">Area</th>
                                                <td className="font-weight-bold">Task</td>
                                                <td className="font-weight-bold">Frequency</td>
                                                <td className="font-weight-bold">Daily</td>
                                                <td className="font-weight-bold">Mon</td>
                                                <td className="font-weight-bold">Tue</td>
                                                <td className="font-weight-bold">Wed</td>
                                                <td className="font-weight-bold">Thu</td>
                                                <td className="font-weight-bold">Fri</td>
                                                <td className="font-weight-bold">Weekend</td>
                                                <td className="font-weight-bold">Monthly</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                console.log(jobProfile)
                                            }

                                            {
                                                jobProfile.areas.map((area, index) => (
                                                    <>
                                                        <tr key={index} className="border">
                                                            <th rowSpan={area.tasks.length + 1} className="font-weight-normal border">{area.area.title}</th>

                                                        </tr>
                                                        {
                                                            area.tasks.map((task, index) => (
                                                                <tr >

                                                                    <td className="border">{ReactHtmlParser(task.task)}</td>
                                                                    <td className="text-capitalize border">{task.frequency}</td>
                                                                    <td className="border">
                                                                        {task.frequency === 'daily' ? (<i className="fas fa-check"></i>) : task.frequency === 'fortnightly' ? (task.day) : ''}


                                                                    </td>
                                                                    <td className="border">{task.frequency === 'weekly' ? (task.day.includes('Monday') ? (<i className="fas fa-check"></i>) : '') : ''}</td>
                                                                    <td className="border">{task.frequency === 'weekly' ? (task.day.includes('Tuesday') ? (<i className="fas fa-check"></i>) : '') : ''}</td>
                                                                    <td className="border">{task.frequency === 'weekly' ? (task.day.includes('Wednesday') ? (<i className="fas fa-check"></i>) : '') : ''}</td>
                                                                    <td className="border">{task.frequency === 'weekly' ? (task.day.includes('Thursday') ? (<i className="fas fa-check"></i>) : '') : ''}</td>
                                                                    <td className="border">{task.frequency === 'weekly' ? (task.day.includes('Friday') ? (<i className="fas fa-check"></i>) : '') : ''}</td>
                                                                    <td className="border">{task.frequency === 'weekend' ? (<i className="fas fa-check"></i>) : ''}</td>
                                                                    <td className="border">{task.frequency === 'monthly' ? (<span>{task.day} of every month</span>) : ''}</td>

                                                                </tr>

                                                            ))
                                                        }
                                                    </>


                                                ))
                                            }


                                        </tbody>
                                    </Table> */}




















                                </div>


                            ) : null

                        }


                        <button className="btn btn-primary" onClick={printDocument}>Export To PDF</button>
                        {/* Always keep same number of td in all tr's and keep all classes same to make pdf work */}



                    </ModalBody>
                    {/* <div className="text-right">
                        <Button color="primary" onClick={toggleModalClassic}>
                            Next
                        </Button>
                    </div> */}

                </Modal >


                {/* Update Site Modal */}

                <Modal Modal
                    isOpen={modalClassic2}
                    toggle={toggleModalClassic2}
                    className="text-center"
                    size="lg"
                >
                    <ModalHeader
                        className="justify-content-start uppercase title text-left"
                        toggle={toggleModalClassic2}
                        tag="h6"
                    >
                        {siteProfile?.site_name}
                    </ModalHeader>
                    <ModalBody>
                        <Form onSubmit={e => onSubmit(e)} encType="multipart/form-data">
                            <Row>



                                <Col md="6">
                                    <label>Client Name</label>
                                    <FormGroup>
                                        {/* <select name="client_id" required className="form-control" type="select" value={client_id} onChange={(e) => onChange(e)}>
                                            <option value="" >Select</option>

                                            {
                                                allClients.clients.map(client => (
                                                    <option value={client.id}>{client.fname + ' ' + client.lname}</option>
                                                ))
                                            }
                                        </select> */}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <label>Contact</label>
                                    <FormGroup>
                                        <Input type="number" value={user ? user?.phone_no : ''} readOnly />
                                    </FormGroup>
                                </Col>
                                <Col md="12">
                                    <label>Address</label>
                                    <FormGroup>
                                        <Input type="text" value={user ? user?.address : ''} readOnly />
                                    </FormGroup>
                                </Col>
                                <Col md="3">
                                    <label>Town</label>
                                    <FormGroup>
                                        <Input type="text" value={user ? user?.town : ''} readOnly />
                                    </FormGroup>
                                </Col>
                                <Col md="3">
                                    <label>Post Code</label>
                                    <FormGroup>
                                        <Input type="number" value={user ? user?.post_code : ''} readOnly />
                                    </FormGroup>
                                </Col>
                                <Col md="3">
                                    <label>Telephone</label>
                                    <FormGroup>
                                        <Input type="number" value={user ? user?.mobile_no : ''} readOnly />
                                    </FormGroup>
                                </Col>
                                <Col md="3">
                                    <label>Email</label>
                                    <FormGroup>
                                        <Input type="email" value={user ? user?.email : ''} readOnly />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <label>Site Name</label>
                                    <FormGroup>
                                        <Input type="text" placeholder={siteProfile?.site_name} onChange={e => onChange(e)} name="site_name" value={site_name} />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <label>Location</label>
                                    <FormGroup>
                                        <Input type="text" placeholder={siteProfile?.location} onChange={e => onChange(e)} name="location" value={location} />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <label>Emergency Contact #</label>
                                    <FormGroup>
                                        <Input type="text" placeholder={siteProfile?.emergency_contact_no} value={emergency_contact_no} name="emergency_contact_no" onChange={e => onChange(e)} />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <label>Emergency Phone #</label>
                                    <FormGroup>
                                        <Input type="number" placeholder={siteProfile?.emergency_phone_no} value={emergency_phone_no} name="emergency_phone_no" onChange={e => onChange(e)} />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <label>Out of Hours Telephone</label>
                                    <FormGroup>
                                        <Input type="number" placeholder={siteProfile?.out_of_hours} value={out_of_hours} name="out_of_hours" onChange={e => onChange(e)} />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <label>Alarm Call Out Number</label>
                                    <FormGroup>
                                        <Input type="number" placeholder={siteProfile?.alarm_call} value={alarm_call} name="alarm_call" onChange={e => onChange(e)} />
                                    </FormGroup>
                                </Col>
                                <Col md="12">
                                    <RegularMap />
                                </Col>
                            </Row>


                            <div className="text-center">
                                <Button className="btn-fill" color="info" type="submit">
                                    Update
                                </Button>
                            </div>
                        </Form>
                    </ModalBody>


                </Modal>
            </div >

        </>
    );
}

const mapStateToProps = state => ({
    allSites: state?.profileReducer?.allSites,
    allJobs: state?.profileReducer?.allJobs,
    user: state?.profileReducer?.userProfile?.user,
    allClients: state?.profileReducer?.allClients,
    isLoading: state?.profileReducer?.isLoading,
    sweetAlert: state?.profileReducer?.sweetAlert,
    deletedMsg: state?.profileReducer?.deletedJob?.message,
    newJobmsg: state?.profileReducer?.newJob?.message,

})

export default connect(
    mapStateToProps,
    {
        getAllSites,
        addNewSite,
        updateSite,
        deleteSite,
        getUserProfile,
        getAllClients,
        getAllJobs,
        deleteJob,
        removeSweetAlert,
        getAllNotes,
        ChangeSelectedJobData
    }
)(ManageJobs);